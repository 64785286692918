import bus from './bus.svg';
import reg from './reg.svg';
import sbahn from './sbahn.svg';
import tram from './tram.svg';
import zug from './zug.svg';

const productIcons = {
    bus: bus,
    national: zug,
    nationalExpress: zug,
    regional: reg,
    suburban: sbahn,
    tourismTrain: zug,
    tram: tram
};

export const getIcon = product => {
    if (productIcons[product]) {
        return productIcons[product];
    }

    return zug;
};
