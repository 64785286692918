import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useEditor } from "../../editor/Editor";
import useApi from "../../../../services/api";
import { formatDistanceToNow } from "date-fns";
import { de } from 'date-fns/locale'

const NewsWidget = props => {
    const [news, setNews] = useState('loading');
    const { display } = useEditor();
    const api = useApi();

    useEffect(() => {
        let q;

        if (props.query) {
            q = props.query;
        } else {
            let citySearch = display.address.match(/.*\d{5}\s*([\w\s()/]+)$/u);
            q = citySearch ? citySearch[1] : display.address;
        }

        setNews('loading');

        api.get(`data/${ display.id }/news`, {
            params: {
                location: q
            }
        }).then(result => setNews(result.data.articles))
    }, [props.query]);

    const inSidebar = useMemo(() => ['l1', 'l2', 'r1', 'r2'].includes(props.position), [props.position]);

    if (news === "loading") {
        return <div className="w-full h-full flex justify-center items-center text-in-xl">Neuigkeiten werden geladen...</div>
    }

    if (inSidebar) {
        return <div className="p-in-5 h-full">
            <div className="text-in-2xl mb-in-2" style={{ color: '#0069af' }}>Aktuelle Neuigkeiten</div>
            { news.slice(0,4).map((item, index) => <div key={index} className="flex items-center mb-in-3">
                <div className="w-2/12 flex-shrink-0">
                    <div className="bg-slate-300 rounded w-full relative overflow-hidden" style={{paddingTop: '100%'}}>
                        { item.image && <img src={item.image} width="200" height="200" className="absolute left-0 top-0 w-full h-full object-cover" /> }
                    </div>
                </div>
                <div className="flex-1 ml-in-4">
                    <div className="line-clamp-2 leading-tight">{ item.title }</div>
                    <div className="text-in-sm text-slate-500">{ item.source } &bull; { formatDistanceToNow(new Date(item.pubDate), {locale: de, addSuffix: true}) }</div>
                </div>
            </div> )}
        </div>
    } else {
        return <div className="px-in-10">
            <div className="text-in-2xl mb-in-2 text-center mt-in-4" style={{ color: '#0069af' }}>Aktuelle Neuigkeiten</div>
            <div className="grid grid-cols-4 gap-in-4">{ news.slice(0,4).map((item, index) =>
                <div key={index} className="text-center">
                    <div className="bg-slate-300 rounded w-6/12 relative overflow-hidden mx-auto mb-in-4" style={{paddingTop: "35%"}}>
                        { item.image && <img src={item.image} className="absolute left-0 top-0 w-full h-full object-cover" /> }
                    </div>
                    <div className="line-clamp-2 leading-tight">{ item.title }</div>
                    <div className="text-in-sm leading-tight mt-in-2 text-slate-500">{ item.source } &bull; { formatDistanceToNow(new Date(item.pubDate), {locale: de, addSuffix: true}) }</div>
                </div>
            )}</div>
        </div>
    }
}

export default NewsWidget