import React, { useState } from "react";
import ReactDOM from "react-dom";
import { motion } from 'framer-motion'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';


const ContactEmergencies = props => {
    const blocksFromHTML = convertFromHTML(props.value);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );

    const [editorState, setEditorState] = useState(EditorState.createWithContent(state));

    const save = () => {
        const rawContent = convertToRaw(editorState.getCurrentContent()),
              html = draftToHtml(rawContent);

        props.onSave(html);
    };

    return props.isOpen ? ReactDOM.createPortal(
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 backdrop-filter backdrop-saturate-50" style={{'--tw-backdrop-blur': 'blur(1px)'}}>
            <motion.div className="absolute w-full h-full bg-gray-400 opacity-50 "></motion.div>
            <div className="bg-white w-11/12 mx-auto rounded-lg shadow-xl z-50 flex md:max-w-5xl overflow-hidden">
                <div className="bg-gray-100 p-8 w-80 flex flex-col flex-shrink-0 border-r relative z-10">
                    <div className="font-medium text-xl mb-4">Notfallkontakte bearbeiten</div>
                    <p class="text-gray-500 mb-10">Hier haben Sie die Möglichkeit, die Notfallkontakte des Hauses zu bearbeiten. Auf der rechten Seite finden Sie hierfür einen Editor, mit dem Sie beliebigen Inhalt einfügen können.</p>
                    <button type="button" className="w-full mt-auto border border-gray-500 rounded p-3 hover:bg-gray-500 hover:text-white" onClick={save}>Speichern</button>
                </div>
                <div className="flex-auto relative z-0">
                    <Editor
                        editorState={editorState}
                        placeholder="Hier tippen"
                        editorStyle={{ minHeight: '200px' }}
                        editorClassName="prose-sm px-10 pb-6"
                        toolbarClassName="p-4 border-0 shadow-xl"
                        // toolbarOnFocus
                        toolbar={{
                            options: ['blockType', 'inline', 'list', 'link'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic']
                            },
                            blockType: {
                                inDropdown: true,
                                options: ['Normal', 'H1']
                            }
                            // inline: { inDropdown: false },
                            // list: { inDropdown: false },
                            // textAlign: { inDropdown: false },
                            // link: { inDropdown: true },
                            // history: { inDropdown: true },
                          }}
                        onEditorStateChange={(state) => setEditorState(state)}
                    />
                </div>
            </div>
        </div>,
        document.body
    ) : null;
    
}

ContactEmergencies.defaultProps = {
    value: ''
}

export default ContactEmergencies