import React from "react";

export const ColorPicker = props => {

    return <div className="grid grid-cols-5 gap-1">
        { props.colors.map(color => <div
            className={`cursor-pointer rounded-full overflow-hidden border border-gray-300 aspect-square transition-all hover:scale-110`}
            key={color}
            style={{
                padding: '2px',
                boxShadow: color == props.color && '0px 0px 10px ' + color,
                transform: color == props.color && 'scale(1.1)'
            }}
            onClick={ () => props.onChange(color) }>
            <div className="h-full w-full rounded-full" style={{ backgroundColor: color }} />
        </div>) }
    </div>

}
