import React, { useState } from "react";
import { useEffect } from "react";
import useApi from "../../services/api";
import AdList from "./AdList";
import AdModal from "./AdModal";
import Statistics from "./Statistics";

const AdsIndex = props => {
    const api = useApi();
    const [ads, setAds] = useState('loading');
    const [adModalOpen, setAdModalOpen] = useState(false);
    const [editableAd, setEditableAd] = useState(AdModal.defaultProps.ad)

    useEffect(() => {
        api.get('ads').then(result => setAds(result.data.data));
    }, []);

    const createAd = () => {
        setEditableAd(AdModal.defaultProps.ad);
        setAdModalOpen(true);
    };

    const saveAd = ad => {
        setAdModalOpen(false);

        if (ad.id) {
            api.patch(`ads/${ ad.id }`, ad).then(({data}) => {
                let index = ads.findIndex(a => a.id == data.id);
                ads[index] = {...data};
                setAds([...ads]);
            });

            
        } else {
            delete ad.id;
            api.post('ads', ad).then(result => setAds([...ads, result.data]))
        }
    };

    const editAd = ad => {
        ad.displays = ad.displays.map(display => display.id);
        setEditableAd(ad);
        setAdModalOpen(true);
    };

    const deleteAd = ad => {
        setAds(ads.filter(a => a.id !== ad.id));

        api.delete(`ads/${ad.id}`);
    }

    const toggleAd = ad => {
        // ad.active = !ad.active;
        let index = ads.findIndex(a => a.id == ad.id);
        ads[index].active = !ads[index].active;
        setAds([...ads]);

        api.patch(`ads/${ ad.id }`, {
            active: ads[index].active
        })
    }

    return (
        <div className="px-6 lg:px-8 py-8 xl:pt-20 w-full max-w-7xl mx-auto">
            <AdModal isOpen={adModalOpen} ad={editableAd} onAbort={() => setAdModalOpen(false)} onSave={saveAd} />

            <div className="lg:flex items-center mb-20">
                <div className="flex-1">
                    <div className="text-brand-500 text-4xl">Werbeanzeigen</div>
                    <p className="text-gray-500 mb-3">Sie können auf Ihren Displays beliebig viele Werbeanzeigen schalten. Ihre Anzeigen können auf verschiedenen Positionen des Displays angezeigt werden. Ebenso steuern Sie, wann und wie lang Ihre Anzeigen auf den Displays angezeigt werden sollen.</p>
                </div>
                <button type="button" className="border flex-none lg:ml-20 border-gray-400 px-10 py-3 rounded hover:bg-gray-400 hover:text-white transition-all" onClick={createAd}>Neue Anzeige</button>
            </div>

            <Statistics />

            { ads === 'loading' ?
                <div className="flex items-center text-gray-400 text-xt justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mr-5 w-10 h-10 text-brand-300 animate-spin" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
                    Anzeigen werden geladen...
                </div>
            : <AdList ads={ads} onEdit={editAd} onDelete={deleteAd} onToggleActive={toggleAd} /> }
        </div>
    )
}

export default AdsIndex;