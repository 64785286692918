import React from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { MediaLibrary } from ".";
import { useState } from "react";
import classNames from "classnames";

const MediaPicker = (props) => {
    const [selectedMedia, setSelectedMedia] = useState(null)

    const dropIn = {
        hidden: { y: "-100vh", opacity: 0, },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };

    return props.isOpen && createPortal(
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            
            <motion.div
                className="absolute w-full h-full bg-gray-400 bg-opacity-50 backdrop-blur-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1}}
                exit={{ opacity: 0 }}
            ></motion.div>
            <motion.div
                className="bg-white w-11/12 mx-auto rounded-lg shadow-xl z-50 overflow-hidden md:max-w-5xl"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <MediaLibrary
                    onMediaSelected={media => setSelectedMedia(media)}
                    filter={props.accept}
                />
                <div className="relative z-10 bg-white p-2 flex justify-end items-center" style={{ boxShadow: '0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 -4px 6px -4px rgb(0 0 0 / 0.1)' }}>
                    <button type="button" onClick={() => props.onAbort()} className="px-3 py-1 border border-gray-300 text-gray-500 rounded hover:border-gray-500 transition-all">Abbrechen</button>
                    <button type="button" className={
                        classNames('px-4 py-2 rounded ml-2 text-white hover:text-white hover:shadow-lg transition-all',
                        {
                            'bg-gray-400': selectedMedia === null,
                            'bg-gray-600 hover:bg-gray-700': selectedMedia !== null 
                        })
                    }
                    disabled={selectedMedia === null}
                    onClick={() => props.onConfirm(selectedMedia)}
                    >Auswahl bestätigen</button>
                </div>
            </motion.div>
            
        </div>
    , document.body);
}

MediaPicker.defaultProps = {
    accept: '.*',
    onConfirm: media => media,
    onAbort: () => true,
}

export default MediaPicker;