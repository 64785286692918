import React from "react";
import { ColorPicker } from "./ColorPicker";
import { Range } from 'react-range'

const availableColors = [
    '#ffffff',
    '#555555',
    '#FF6900',
    '#FCB900',
    '#7BDCB5',
    '#00D084',
    '#8ED1FC',
    '#0693E3',
    '#ABB8C3',
    '#F78DA7',
];

const TextSettings = (props) => {
    const { text, backgroundColor, fontSize, fontColor, setProps } = props;

    return (<div>
        <div className="font-medium mb-2">Hinweis oder Text schreiben:</div>
        <textarea className="w-full h-40 p-2 mb-8 rounded border-gray-200 shadow-inner" value={text || ''} onChange={e => setProps({ ...props, text: e.target.value })} />
        
        <div className="mb-8">
            <div className="font-medium mb-2">Schriftgröße:</div>
            <Range
                step={0.1}
                min={1}
                max={5}
                values={[fontSize || 2]}
                onChange={values => setProps({ ...props, fontSize: values[0] })}
                renderTrack={({ props, children }) => (
                    <div {...props} className="w-full h-2 bg-gray-300 rounded-full">{children}</div>
                )}
                renderThumb={({ props }) => (
                    <div {...props} className="w-5 h-5 bg-gray-600 rounded-full" />
                )}
            />
        </div>

        <div className="flex gap-10">
            <div className="flex-1">
                <div className="font-medium mb-2">Hintergrundfarbe:</div>
                <ColorPicker colors={ availableColors } color={backgroundColor || '#ffffff'} onChange={color => setProps({ ...props, backgroundColor: color })} /> 
            </div>
            <div className="flex-1">
                <div className="font-medium mb-2">Textfarbe:</div>
                <ColorPicker colors={ availableColors } color={fontColor || '#000'} onChange={color => setProps({ ...props, fontColor: color })} /> 
            </div>
        </div>

        

    </div>)
}

TextSettings.defaultProps = {
    text: '',
    backgroundColor: null,
    fontSize: 2,
    fontColor: null
}

export { TextSettings }