import React from "react";
import useApi from "../../services/api"
import { useMediaLibrary } from ".";
import CollectionItem from "./CollectionItem";

const CollectionPane = (props) => {
    const { collections, setCollections, setSelectedCollection, newCollectionName, setNewCollectionName } = useMediaLibrary();
    const api = useApi();

    const saveNewCollection = () => {
        api.post('media_collections', {
            name: newCollectionName
        }).then(result => {
            setCollections([ ...collections, result.data ]);
            setSelectedCollection(result.data);
            setNewCollectionName(false)
        })
    }

    const collectionList = [{
        id: null,
        name: 'Nicht zugeordnet'
    }, ...collections].map(collection => {
        return <CollectionItem key={collection.id} collection={collection} />
    })

    return (
        <aside className="media-folder-pane relative z-0 w-60 bg-gray-50 flex flex-col">
            { collectionList }
            { newCollectionName !== false &&
                <form onSubmit={e => { e.preventDefault(); saveNewCollection();}} className="flex items-center group py-3 pl-8 pr-3 bg-slate-200 text-lg truncate cursor-pointer transition-all">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 flex-shrink-0 text-slate-500" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                    </svg>
                    <div className="flex-1">
                        <input type="text" autoFocus={true}
                            value={newCollectionName}
                            className="w-full rounded p-1 border-slate-300"
                            onChange={e => setNewCollectionName(e.target.value)}
                            onBlur={e => {
                                if (e.target.value.trim() === '') {
                                    setNewCollectionName(false);
                                } else {
                                    saveNewCollection()
                                }
                            }}
                        />
                    </div>
                </form>
            }
        </aside>
    )
};

export default CollectionPane;