import classNames from "classnames";
import React, { useState } from "react";
import positionIcons from "./position-icons";
import { format, formatDistance, formatDuration, intervalToDuration } from 'date-fns';
import { de } from 'date-fns/locale';
import { useEffect } from "react";

const AdList = props => {
    const [activeAd, setActiveAd] = useState(null);

    const secondsToDuration = seconds => {
        const duration = intervalToDuration({
            start: 0,
            end: seconds * 1000
        });

        return duration.hours.toString().padStart(2, "0") + ':'
            + duration.minutes.toString().padStart(2, "0") + ':'
            + duration.seconds.toString().padStart(2, "0")
    };

    const deleteAd = ad => {
        if (window.confirm("Möchten Sie die Anzeige wirklich löschen?")) {
            props.onDelete(ad);
        }
    }

    useEffect(() => {
        if (props.ads.length) {
            setActiveAd(props.ads[0]);
        }
    }, [props.ads]);

    const list = props.ads.map(ad => (
        <div key={ad.id} className="mb-5">
            <div className="bg-white rounded-md shadow-md flex overflow-hidden p-5 items-center relative z-10 cursor-pointer" onClick={() => setActiveAd(ad)}>
                <img src={positionIcons[ad.position]} className="h-12 opacity-50 mr-5" />
                <div className="flex-auto">
                    <div className="text-xl">{ ad.name }</div>
                    <div className="text-sm text-gray-400">{ ad.client }</div>
                </div>
                { ad.active ? <div className="text-green-700 w-20 text-center mx-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-1 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    aktiv
                </div> : <div className="text-red-700 w-20 text-center mx-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-1 mx-auto " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    nicht aktiv
                </div> }

                <div className="mx-5 text-center text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-1 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    { Math.round(ad.placements.today / 60) } Min.
                </div>
            </div>
            <div className={classNames('bg-gray-200 rounded-b relative z-0 overflow-hidden transition-all duration-500', {
                'max-h-0': !activeAd || activeAd.id !== ad.id,
                'max-h-60': activeAd && activeAd.id === ad.id
            })}>
                <div className="p-8 flex text-sm text-gray-500">
                    <div className="mr-10">
                        <h3 className="font-medium text-base mb-2 text-gray-600">Geschaltet auf:</h3>
                        <ul className="list-disc list-outside pl-4">
                            { ad.displays.map(display => <li key={display.id}>{ display.name }</li>)}
                        </ul>
                    </div>
                    <div className="mr-10">
                        <h3 className="font-medium text-base mb-2 text-gray-600">Taktung:</h3>
                        { ad.hourly } Anzeigen / Stunde<br />
                        { ad.duration } Sekunden / Anzeige
                    </div>
                    <div className="mr-10">
                        <h3 className="font-medium text-base mb-2 text-gray-600">Einblendungen:</h3>
                        Heute: { secondsToDuration(ad.placements.today) }<br />
                        30 Tage: { secondsToDuration(ad.placements['30days']) }
                    </div>
                    <div className="ml-auto flex flex-col items-start text-base">
                        <button type="button" className="flex items-center hover:text-gray-700" onClick={() => props.onEdit({...ad})}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                            bearbeiten
                        </button>
                        <button type="button" className="flex items-center mt-1 hover:text-red-700" onClick={() => deleteAd(ad)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            löschen
                        </button>
                        <button type="button" className={`flex items-center mt-1 ${ad.active ? 'text-red-700 hover:text-red-500' : 'text-lime-600 hover:text-lime-700'}`} onClick={() => props.onToggleActive(ad)}>
                            { ad.active ? <>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                                deaktivieren
                            </> : <>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                                aktivieren
                            </> }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ));

    return props.ads.length ?
        list :
        <div className="text-xl">Bisher wurden keine Anzeigen angelegt.</div>
};

export default AdList