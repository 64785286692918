import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { motion } from 'framer-motion'
import positionIcons from "./position-icons";
import classNames from "classnames";
import useApi from "../../services/api";
import { formatDuration, intervalToDuration } from "date-fns";
import { de } from "date-fns/locale";
import MediaPicker from "../media-library/MediaPicker";

const positions = [
    { key: 'l1', name: 'links oben', icon: positionIcons.l1 },
    { key: 'l2', name: 'links unten', icon: positionIcons.l2 },
    { key: 'm1', name: 'mitte oben', icon: positionIcons.m1 },
    { key: 'm2', name: 'mitte unten', icon: positionIcons.m2 },
    { key: 'r1', name: 'rechts oben', icon: positionIcons.r1 },
    { key: 'r2', name: 'rechts unten', icon: positionIcons.r2 },
    { key: 'modal', name: 'als Fenster', icon: positionIcons.modal }
]

const AdModal = (props) => {
    const [ad, setAd] = useState(props.ad)
    const [displays, setDisplays] = useState([])
    const api = useApi();
    const [mediaPickerOpen, setMediaPickerOpen] = useState(false)

    const windowVariants = {
        closed: { translateY: 100, opacity: 0 },
        open: { translateY: 0, opacity: 1 }
    }

    useEffect(() => api.get('displays').then(result => {
        setDisplays(result.data);

        if (ad.id === null) {
            setAd({...ad, displays: result.data.map(display => display.id)})
        }
    }), []);

    useEffect(() => setAd(props.ad), [props.ad]);

    const displayList = displays && displays.map(display => (
        <div key={display.id} className={classNames(
            'cursor-pointer transition-all relative text-center text-xs hover:opacity-100',
            ad.displays && ad.displays.includes(display.id) ? 'opacity-100' : 'opacity-50'
        )} onClick={() => {
            if (ad.displays.includes(display.id)) {
                setAd({ ...ad, displays: ad.displays.filter(id => id !== display.id)});
            } else {
                // ad.displays.push(display.id);
                setAd({ ...ad, displays: [...ad.displays, display.id] })
            }
        }}>
            <div className="w-full aspect-video border-2 border-b-4 mb-1 border-gray-400 bg-gray-100 rounded"></div>
            { display.name }
            { ad.displays && ad.displays.includes(display.id) && <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-700 absolute -right-2 -top-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg> }
        </div>
    ));

    const placements = useMemo(() => {
        const hourly = Math.min(3600, ad.hourly * ad.duration) * ad.displays.length;
        const daily = hourly * 24;
        const weekly = daily * 7;
        const monthly = daily * 31;
        const yearly = daily * 365;

        return {
            hourly,
            daily,
            weekly,
            monthly,
            yearly
        }
    }, [ad.displays, ad.hourly, ad.duration])

    const secondsToDuration = seconds => {
        if (seconds === 0) {
            return <span className="text-red-800">0 Minuten</span>
        }

        const duration = intervalToDuration({
            start: 0,
            end: seconds * 1000
        });

        duration.minutes += (duration.hours * 60)
        duration.minutes += (duration.days * 24 * 60);
        duration.minutes += (duration.months * 24 * 60 * 30);
        duration.minutes += (duration.years * 24 * 60 * 365)

        return formatDuration(duration, {
            format: ['minutes', 'seconds'],
            zero: false,
            delimiter: ', ',
            locale: de
        })
    };

    return props.isOpen && createPortal(
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">

            <MediaPicker
                isOpen={mediaPickerOpen}
                accept="image/*"
                onAbort={() => setMediaPickerOpen(false)}
                onConfirm={media => {
                    setAd({...ad, image: `${ process.env.REACT_APP_API_ENDPOINT }/images/${ media.id}`})
                    // setPersonData({...personData, image: media.id})
                    setMediaPickerOpen(false)
                }} />

            <motion.div className="absolute w-full h-full bg-gray-400 bg-opacity-50" initial={{ opacity: 0 }} animate={{ opacity: 1}}></motion.div>
            <motion.div className="bg-white w-11/12 mx-auto rounded-lg shadow-xl z-50 overflow-y-auto md:max-w-5xl" variants={windowVariants} animate={props.isOpen ? 'open' : 'closed'}>
                <div className="flex">
                    <div className="w-4/12 flex-none bg-gray-100 p-8">
                        <h3 className="text-2xl font-medium">{ ad.id ? 'Anzeige bearbeiten' : 'Anzeige erstellen' }</h3>
                        <p className="text-sm mt-4 text-gray-500">Legen Sie rechts bitte die Details zu Ihrer Anzeige fest. Wählen Sie vor allem die Position und die Displays, in denen die Anzeige geschaltet werden soll.</p>
                        <p className="text-sm mt-4 text-gray-500">Hinweis: die gewünschte Schaltdauer ist ein Richtwert. Auch wenn die Anzeige normalerweise wie angelegt auf den Displays erscheint, kann die endgültige Schaltung abweichen.</p>
                        <h3 className="text-xl mt-6 font-medium">Voraussichtliche Schaltung</h3>
                        <div className="flex mt-3 items-center">
                            <div>Stündlich</div>
                            <div className="flex-auto border-b-2 mt-2 mx-2 border-dotted border-gray-300"></div>
                            <div className="ml-auto text-ellipsis whitespace-nowrap">{ secondsToDuration(placements.hourly) }</div>
                        </div>
                        <div className="flex mt-3 items-center">
                            <div>Täglich</div>
                            <div className="flex-auto border-b-2 mt-2 mx-2 border-dotted border-gray-300"></div>
                            <div className="ml-auto text-ellipsis whitespace-nowrap">{ secondsToDuration(placements.daily) }</div>
                        </div>
                        <div className="flex mt-3 items-center">
                            <div>Wöchentlich</div>
                            <div className="flex-auto border-b-2 mt-2 mx-2 border-dotted border-gray-300"></div>
                            <div className="ml-auto text-ellipsis whitespace-nowrap">{ secondsToDuration(placements.weekly) }</div>
                        </div>
                        <div className="flex mt-3 items-center">
                            <div>Monatlich</div>
                            <div className="flex-auto border-b-2 mt-2 mx-2 border-dotted border-gray-300"></div>
                            <div className="ml-auto text-ellipsis whitespace-nowrap">{ secondsToDuration(placements.monthly) }</div>
                        </div>
                        <div className="flex mt-3 items-center">
                            <div>Jährlich</div>
                            <div className="flex-auto border-b-2 mt-2 mx-2 border-dotted border-gray-300"></div>
                            <div className="ml-auto text-ellipsis whitespace-nowrap">{ secondsToDuration(placements.yearly) }</div>
                        </div>
                    </div>
                    <form onSubmit={e => {
                        e.preventDefault();
                        props.onSave(ad);
                    }} className="flex-auto p-8 xl:p-10">
                        <div className="mb-4">
                            <label className="block mb-1 text-sm text-gray-500">Anzeigen-Titel (für interne Zwecke):</label>    
                            <input type="text" className="text-xl py-3 w-full rounded border-gray-200" value={ad.name} onChange={(e) => setAd({...ad, name: e.target.value})} />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-sm text-gray-500">Kunde (optional):</label>    
                            <input type="text" className="w-full p-2 rounded border-gray-200" value={ad.client ? ad.client : ''} onChange={e => setAd({...ad, client: e.target.value})} />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-sm text-gray-500">URL zur Anzeigegrafik:</label>
                            <div className="flex">
                                <input type="url" value={ad.image} placeholder="https://" className="w-full p-2 rounded rounded-r-none border-gray-200 border-r-0" onChange={e => setAd({...ad, image: e.target.value})} />
                                <button type="button" className="flex-none px-5 border  text-gray-500 rounded-r hover:bg-gray-200 " onClick={() => setMediaPickerOpen(true)}>Bild wählen</button>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm text-gray-500">Anzeigenschaltung - Dauer und Frequenz:</label>
                            <div className="flex items-center bg-gray-100 p-2">
                                <input type="number" value={ad.hourly} min="1" max="60" step={1} className="border-gray-200 p-2 w-16 text-center rounded hide-spinner"
                                    onChange={e => setAd({...ad, hourly: e.target.value})} />
                                <div className="mx-4">Einblendungen pro Stunde und Display für je</div>
                                <input type="number" value={ad.duration} min="10" max="1800" step="10" className="border-gray-200 p-2 w-16 text-center rounded hide-spinner" onChange={e => setAd({...ad, duration: e.target.value})} />
                                <div className="mx-4">Sekunden</div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm text-gray-500">Position:</label>
                            <div className="grid grid-cols-7 gap-4">
                                { positions.map(pos => (
                                    <div key={pos.key} className={classNames(
                                        'cursor-pointer transition-all relative text-center text-xs hover:opacity-100',
                                        ad.position && ad.position === pos.key ? 'opacity-100' : 'opacity-30'
                                    )} onClick={() => setAd({...ad, position: pos.key })}>
                                        <img src={pos.icon} className="max-w-full h-auto mb-1" />
                                        { pos.name }
                                        { ad.position && ad.position === pos.key && <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-700 absolute -right-2 -top-2" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                        </svg> }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm text-gray-500">Displays:</label>
                            <div className="grid grid-cols-5 gap-4">{ displayList }</div>
                        </div>
                        
                        <div className="flex items-center mt-10">
                            <button type="button" className="border border-gray-200 px-5 py-2 rounded" onClick={() => props.onAbort()}>Abbrechen</button>
                            <button type="submit" className={classNames(
                                'border border-gray-500 text-gray-700 px-10 py-3 rounded ml-auto hover:bg-gray-500 hover:text-white transition-all',
                                { 'opacity-50 pointer-events-none': !ad.name || !ad.image || ad.displays.length == 0 }
                            )} disabled={!ad.name || !ad.image || ad.displays.length == 0}>Speichern</button>
                        </div>
                    </form>
                </div>
            </motion.div>
        </div>
    , document.body);
}

AdModal.defaultProps = {
    ad: {
        id: null,
        name: '',
        client: '',
        displays: [],
        hourly: 10,
        duration: 30,
        link: '',
        image: '',
        position: 'l1'
    },
    onAbort: () => true,
    onSave: () => true
}

export default AdModal