import { useState } from "react";
import ContactEmergencies from "./ContactEmergencies";
import ContactPerson from "./ContactPerson";

const ContactSettings = (props) => {
    const { persons, emergencyContacts, setProps } = props;
    const [emergenciesOpen, setEmergenciesOpen] = useState(false);

    const personsList = persons && persons.map((person, index) => <ContactPerson
        key={index}
        person={person}
        onUpdatePerson={person => {
            persons[index] = person;
            setProps({ persons, emergencyContacts })
        }}
        onDeletePerson={() => {
            persons.splice(index, 1);
            setProps({ persons, emergencyContacts })
        }}
    />)

    const addPerson = () => {
        persons.push({})

        setProps({ persons, emergencyContacts })
    }

    const updateEmergenyContacts = html => {
        console.log(html)
        setProps({ persons, emergencyContacts: html })

        setEmergenciesOpen(false);
    }; 

    return (
        <div>

            <ContactEmergencies isOpen={emergenciesOpen} value={props.emergencyContacts || ''} onSave={updateEmergenyContacts} />

            { personsList }

            <div className="text-center">
                <button className="mt-5 mx-auto py-2 px-10 inline-block text-center border-slate-500 border rounded hover:bg-slate-500 hover:text-white" onClick={addPerson}>Person hinzufügen</button>
                <button className="mt-5 mx-auto py-2 px-10 inline-block text-center border-slate-500 border rounded hover:bg-slate-500 hover:text-white" onClick={() => setEmergenciesOpen(true)}>Notfallkontakte bearbeiten</button>
            </div>

        </div>
    )
}

ContactSettings.defaultProps = {
    persons: [],
    emergencyContacts: ''
}

export default ContactSettings