import React from "react";
import Slider from "react-slick";
import "./image-slider.scss";

export const ImageSliderWidget = ({ images }) => {

    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 8000
    };

    const slides = images.map((image, index) => (
        <div key={index}>
            <img src={`${ process.env.REACT_APP_API_ENDPOINT }/images/${image.id}/thumb/1600x900`} className="w-full h-full object-cover" />
        </div>
    ));
    
    return <div className="border-red-700">
        <Slider {...sliderSettings} className="image-slider">
            { slides }
        </Slider>
    </div>
};