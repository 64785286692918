import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import TopLogo2 from '../assets/img/top-logo-2.png';
import Logo from '../assets/img/logo.png';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const { login } = useAuth();

    const handleLogin = (e) => {
        e.preventDefault();

        login({ email, password })
            .then(user => navigate('/', { replace: true }))
            .catch(e => setError(Object.values(e.response.data.errors).map(error => error[0]).join("\n")))
    }

    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div>
                <img src={Logo} className="mx-auto mb-8" />
                <div className="bg-white w-full max-w-md p-8 rounded-lg shadow-xl">
                    { error && <div className="bg-red-100 p-3 leading-snug mb-5 rounded">
                        <div className="text-red-800 font-medium mb-2">Ups, das hat leider nicht geklappt.</div>
                        <div className="text-red-800">{ error }</div>
                    </div> }

                    <form onSubmit={handleLogin}>
                        <input
                            type="email"
                            className="w-full border border-gray-200 rounded mb-2"
                            placeholder="E-Mail"
                            value={email}
                            required
                            onChange={e => setEmail(e.target.value)} />
                        <input
                            type="password"
                            className="w-full border border-gray-200 mb-4"
                            placeholder="Passwort"
                            value={password}
                            required
                            onChange={e => setPassword(e.target.value)} />
                        <button type="submit" className="w-full bg-gray-600 text-white rounded p-3 hover:bg-gray-700 hover:shadow-xl">Anmelden</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;