import React, { useState } from "react";
import useApi from "../../services/api"
import { useEffect } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import CollectionPane from "./CollectionPane";
import MediaPane from "./MediaPane";
import Toolbar from './Toolbar';
// import DetailsPane from "./DetailsPane";

const MediaLibraryContext = React.createContext();

const MediaLibrary = (props) => {

    const api = useApi()
    const [viewType, setViewType] = useState('grid')
    const [collections, setCollections] = useState([]);
    const [media, setMedia] = useState([])
    const [mediaLoading, setMediaLoading] = useState(false)
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [selectedMedia, setSelectedMedia] = useState(null)
    const [newCollectionName, setNewCollectionName] = useState(false)

    useEffect(() => {
        api.get('media_collections').then(result => setCollections(result.data))
    }, [])

    useEffect(() => {
        setSelectedMedia(null)
        setMediaLoading(true);

        api.get('media', {
            params: {
                collection: selectedCollection ? selectedCollection.id : null,
                filter: props.filter
            }
        }).then(result => {
            setMedia(result.data)
            setMediaLoading(false);
        })
    }, [selectedCollection])

    useEffect(() => props.onMediaSelected(selectedMedia), [selectedMedia, props]);

    const uploadFile = file => {
        const tempId = Math.floor(Math.random() * 1000);

        const newFile = {
            id: tempId,
            uploading: true,
            name: file.name,
            size: file.size,
            uploaded: 0,
            created_at: file.lastModifiedDate
        };

        media.unshift(newFile);

        setMedia(media);

        let fd = new FormData();
        fd.append('file', file);

        if (selectedCollection && selectedCollection.id !== null) {
            fd.append('collection', selectedCollection.id);
        }

        api.post('media', fd, {
            headers: {
                'Content-type': 'multipart/form-data'
            },
            onUploadProgress: (e) => {
                media.find(m => m.id === tempId).uploaded = e.loaded;

                setMedia([...media]);
            }
        }).then(result => {
            let index = media.findIndex(m => m.id === tempId)

            media[index] = result.data;

            setMedia([...media]);
        })
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <MediaLibraryContext.Provider value={{
                media, setMedia,
                selectedMedia, setSelectedMedia,
                viewType, setViewType,
                collections, setCollections,
                selectedCollection, setSelectedCollection,
                newCollectionName, setNewCollectionName,
                uploadFile
            }}>
                <div className="media-library flex flex-wrap bg-white relative overflow-hidden">
                    <Toolbar />
                    <CollectionPane />
                    <MediaPane isLoading={mediaLoading} />
                    {/* <DetailsPane /> */}
                </div>
            </MediaLibraryContext.Provider>
        </DndProvider>
    )
};

MediaLibrary.defaultProps = {
    onMediaSelected: selectedMedia => selectedMedia,
    accept: '*'
}

const useMediaLibrary = () => React.useContext(MediaLibraryContext);

export { MediaLibrary, useMediaLibrary }