import { React, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { GoogleMap, Autocomplete, Marker, useJsApiLoader } from '@react-google-maps/api';
import useApi from "../../services/api";
import { NavLink } from "react-router-dom";
import { motion } from 'framer-motion'

const mapsLibraries = ['places'];

const CreateDisplay = (props) => {
    
    const { isLoaded: mapsLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBX0L3HA2Zp0Oca1AnZhBK8zQsWnVpA4zI",
        libraries: mapsLibraries
    })
    
    const api = useApi();

    const [map, setMap] = useState(null)
    const [autocomplete, setAutocomplete] = useState(null)
    const [marker, setMarker] = useState(null)
    const [loading, setLoading] = useState(false)
    const [position, setPosition] = useState()
    const [name, setName] = useState('')
    const [display, setDisplay] = useState(null)
    const [address, setAddress] = useState(null)

    const onMapLoad = useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        map.setCenter({
            lat: 51.165691,
            lng: 10.451526
        });
        setMap(map)
      }, [])

    const onAutocompleteLoad = useCallback(ac => setAutocomplete(ac), []);
    const onMarkerLoaded = useCallback(m => setMarker(m), []);

    const placeSelected = () => {
        const place = autocomplete.getPlace();

        map.panTo(place.geometry.location);
        map.setZoom(15);

        // console.log(place)
        marker.setPosition(place.geometry.location);

        let streetName = [
            place.address_components.filter(comp => comp.types.includes('route'))[0],
            place.address_components.filter(comp => comp.types.includes('street_number'))[0]
        ].map(comp => comp ? comp.long_name : null).join(' ');

        setName(streetName);
        
        setPosition(place.geometry.location);

        console.log(place);

        let addressc = {
            street: '',
            zip: '',
            city: ''
        }

        place.address_components.forEach(comp => {
            let value = comp.long_name,
                type = comp.types[0];

            if (type == 'route') addressc.street = value;
            if (type == 'locality') addressc.city = value;
            if (type == 'postal_code') addressc.zip = value;
        })

        setAddress(`${addressc.street}, ${addressc.zip} ${addressc.city}`);
    };

    const onMarkerDragged = () => setPosition(marker.getPosition())

    const saveDisplay = () => {
        api.post('displays', {
            name,
            address,
            position
        }).then(response => setDisplay(response.data))
    }

    const windowVariants = {
        closed: { translateY: 100, opacity: 0 },
        open: { translateY: 0, opacity: 1 }
    }

    return props.isOpen ? ReactDOM.createPortal(
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <motion.div className="absolute w-full h-full bg-gray-400 bg-opacity-50" initial={{ opacity: 0 }} animate={{ opacity: 1}}></motion.div>
            <motion.div className="bg-white w-11/12 mx-auto rounded-lg shadow-xl z-50 overflow-y-auto md:max-w-5xl" variants={windowVariants} animate={props.isOpen ? 'open' : 'closed'}>
                
                { display ? <div className="p-10 text-center">
                    <div className="text-4xl mb-3 font-light">Fast fertig...</div>
                    <p className="text-gray-500">Ihr neues Display nun fast startklar. Als Nächstes installieren Sie bitte die Poartdo App auf dem Display. Wenn Sie nach dem Installations-Code gefragt werden, geben Sie bitte folgenden Code ein:</p>
                    <div className="inline-block my-10 bg-gray-700 text-white font-mono rounded-md text-5xl font-extrabold px-3 py-2 shadow-lg border-gray-500 border-4">{ display.code }</div>
                    <p className="text-gray-500">Möchten Sie nun mit der Einrichtung Ihres neuen Displays fortfahren oder dies später erledigen?</p>
                    <div className="mt-6 flex items-center justify-between">
                        <button className="px-4 bg-transparent p-3 rounded-lg border-gray-100 border text-gray-500 hover:bg-gray-100 hover:text-gray-400" onClick={props.close}>später</button>
                        <NavLink to={`/edit/${ display.id }`} className="px-4 bg-gray-500 p-3 rounded-lg text-white hover:bg-gray-400">
                                Display einrichten &rarr;
                        </NavLink>
                    </div>
                </div>   
                :
                <div className="flex items-center">
                    <div className="w-5/12 flex-none bg-gray-400" style={{height: '500px'}}>
                        {mapsLoaded ? <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '100%'
                            }}
                            // center={position}
                            zoom={6}
                            onLoad={onMapLoad}
                            options={{
                                mapTypeControl: true,
                                scaleControl: false,
                                streetViewControl: false,
                                fullscreenControl: false
                            }}
                            // onUnmount={onUnmount}
                            >
                                <Marker
                                    onLoad={onMarkerLoaded}
                                    draggable={true}
                                    onDragEnd={onMarkerDragged}
                                ></Marker>
                        </GoogleMap> : null}
                    </div>
                    <div className="w-auto px-10">
                        <div className="text-2xl mb-3">Neues Display anlegen</div>
                        <p className="text-gray-500">Um ein neues Display anzulegen, legen Sie bitte zunächst fest, wo es seinen Einsatz finden wird. Geben Sie hierfür einfach die Adresse des Displays ein und kontrollieren Sie die Position auf der Karte.</p>
                        <div className="mt-10 text-left">
                            <label className="text-sm block mb-2">Adresse des neuen Displays:</label>
                            { mapsLoaded ? <Autocomplete
                                onLoad={onAutocompleteLoad}
                                fields={['address_components', 'geometry', 'formatted_address']}
                                restrictions={{
                                    country: 'de'
                                }}
                                onPlaceChanged={placeSelected}
                            >
                                <input type="text" className="w-full rounded-md border-gray-300 text-xl placeholder-gray-500" />
                            </Autocomplete> : null }
                        </div>
                        <div className="mt-5 text-left">
                            <label className="text-sm block mb-2">Wie möchten Sie Ihr Display nennen? (optional)</label>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} className="w-full rounded-md border-gray-300 focus:shadow-inner" />
                        </div>
                        <div className="flex justify-between pt-5 mt-5">
                            <button className="px-4 bg-transparent p-3 rounded-lg border-gray-100 border text-gray-500 hover:bg-gray-100 hover:text-gray-400" onClick={props.close}>Abbrechen</button>
                            <button
                                disabled={!position}
                                onClick={saveDisplay}
                                className={`px-4 bg-gray-500 p-3 rounded-lg text-white hover:bg-gray-400 ${ !position ? 'pointer-events-none opacity-50' : ''}`}>
                                    Weiter &rarr;
                            </button>
                        </div>
                    </div>
                </div> }
            </motion.div>
        </div>,
        document.body
    ) : null;

}

export default CreateDisplay;