import { TextWidget } from "./TextWidget";
import { TextSettings } from "./TextSettings";

const module = {
    name: 'Hinweis / Text',
    description: 'Zeigen Sie Ihren Mietern einen Hinweis an, z.B. bei geplanten Wartungen oder Arbeiten im Haus.',
    icon: 'test',
    properties: {
        text: '',
        backgroundColor: null,
        fontSize: 2,
        fontColor: null
    },
    widget: TextWidget,
    settings: TextSettings
}

export default module