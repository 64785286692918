import React, { useState } from "react";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import ContactModule from '../modules/Contact'
import WeatherModule from '../modules/Weather'
import NewsModule from '../modules/News'
import HafasModule from '../modules/Hafas'
import { FacebookModule } from '../modules/Facebook'
import ImageSliderModule from '../modules/ImageSlider'
import TextModule from '../modules/Text'

const EditorContext = React.createContext();

const Editor = (props) => {

    const modules = {
        'contact': ContactModule,
        'weather': WeatherModule,
        'news': NewsModule,
        'hafas': HafasModule,
        'facebook': FacebookModule,
        'image-slider': ImageSliderModule,
        'text': TextModule
    }

    // const [widgets, setWidgets] = useState({
    //     leftTop: {
    //         widget: 'contact',
    //         props: {
    //             persons: [
    //                 {
    //                     name: 'Chris Ribal',
    //                     position: 'Webentwickler'
    //                 }
    //             ]
    //         }
    //     },
    //     leftBottom: null,
    //     mainTop: null,
    //     mainBottom: null,
    //     rightTop: null,
    //     rightBottom: null
    // });

    const { display, widgets, setWidgets } = props;

    const [selected, setSelected] = useState(null)

    return (
        <DndProvider backend={HTML5Backend}>
            <EditorContext.Provider value={{ display, widgets, setWidgets, selected, setSelected, modules }}>
                { props.children }
            </EditorContext.Provider>
        </DndProvider>
    )
}

const useEditor = () => React.useContext(EditorContext);

export { Editor, useEditor };