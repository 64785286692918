import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import MediaPicker from "../../../media-library/MediaPicker";

const ContactPerson = ({ person, onUpdatePerson, onDeletePerson }) => {
    const [mediaPickerOpen, setMediaPickerOpen] = useState(false)

    const [personData, setPersonData] = useState({...person});

    useEffect(() => onUpdatePerson(personData), [personData])

    return <div className="mt-4 p-4 bg-white shadow-md rounded-md flex relative items-start">

        <MediaPicker
            isOpen={mediaPickerOpen}
            accept="image/*"
            onAbort={() => setMediaPickerOpen(false)}
            onConfirm={media => {
                setPersonData({...personData, image: media.id})
                setMediaPickerOpen(false)
            }}
        />

        <button type="button" className="absolute text-red-600 -right-3 -top-3 hover:scale-125 hover:drop-shadow-lg filter transition-all"
            onClick={() => onDeletePerson()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
        </button>

        <div className="w-4/12">
            <div
                onClick={() => setMediaPickerOpen(true) }
                className="w-full cursor-pointer bg-gray-200 border border-gray-200 rounded-md overflow-hidden relative"
                style={{ paddingTop: '125%' }}>
                
                { person.image
                ?
                    <img className="absolute left-0 top-0 w-full h-full object-cover" src={`${ process.env.REACT_APP_API_ENDPOINT }/images/${person.image}/thumb/100x125`} />
                :
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-auto absolute bottom-0 left-0 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                    </svg>
                }
                
            </div>
        </div>

        <div className="flex-0 ml-5">
            <input type="text"
                placeholder="Vor-/Nachname"
                className="w-full rounded border-none bg-transparent outline-hidden text-lg p-0"
                value={personData.name}
                onChange={e => setPersonData({...personData, name: e.target.value})}
                />

            <input type="text"
                placeholder="Position"
                className="w-full rounded border-none bg-transparent text-sm p-0 text-slate-500"
                value={personData.position}
                onChange={e => setPersonData({...personData, position: e.target.value})}
                />

            <div className="mt-3 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 " viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <input type="tel"
                    className="ml-2 flex-auto p-0 border-none bg-transparent"
                    placeholder="Telefon (opt)"
                    value={personData.phone}
                    onChange={e => setPersonData({...personData, phone: e.target.value})}
                    />
            </div>

            <div className="mt-1 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 " viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <input type="email"
                    className="ml-2 flex-auto p-0 border-none bg-transparent"
                    placeholder="E-Mail (opt)"
                    value={personData.email}
                    onChange={e => setPersonData({...personData, email: e.target.value})}
                />
            </div>
        </div>
    </div>
}

ContactPerson.defaultProps = {
    person: {
        name: '',
        phone: '',
        email: '',
        position: ''
    }
}

export default ContactPerson;