import React, { useState } from "react";
import { useEffect } from "react";
import { useEditor } from "../../editor/Editor";

const NewsSettings = props => {
    const [queryString, setQueryString] = useState('')
    const { display } = useEditor(); 

    useEffect(() => {
        if (props.query) {
            return setQueryString(props.query);
        }

        let citySearch = display.address.match(/.*\d{5}\s*([\w\s()/]+)$/u);
        
        setQueryString(citySearch ? citySearch[1] : display.address);
    }, [props.query]);
    
    return <div>
        <div className="font-medium mb-2">News-Artikel von Google News</div>
        <div className="text-sm leading-snug mb-5">Die Beiträge für die aktuellen Neuigkeiten werden von <a href="https://news.google.com" target="_blank" className="underline">Google News</a> abgerufen. Hierfür wird nach der Stadt gesucht, in dem Ihr Display steht. Mit den nachfolgenden Feld können Sie den Google News Suchbegriff anpassen.</div>

        <div className="relative">
            <input
                type="text"
                value={queryString}
                onChange={e => setQueryString(e.target.value)}
                onBlur={e => props.setProps({ query: queryString })}
                className="w-full border-gray-300 rounded-full relative z-0 p-3 pl-12 shadow-inner" />
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute top-4 z-10 left-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        </div>
    </div>
}

NewsSettings.defaultProps = {
    query: ''
}

export default NewsSettings;