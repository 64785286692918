import React from "react";

const ContractorList = props => {

    if (props.contractors === 'loading') {
        return <div>Lade Servicepartner</div>;
    }

    const deleteContractor = con => {
        if (window.confirm("Möchten Sie den Dienstleister wirklich löschen?")) {
            props.onClickRemove(con);
        }
    };

    const conList = props.contractors.map(contractor => {
        return <div key={contractor.id} className="mb-5">
            <div className="bg-white rounded-md shadow-md flex overflow-hidden p-5 items-center relative z-10">
                <div className="w-5/12 flex-none">
                    <div className="text-xl">{ contractor.name }</div>
                    <div className="text-sm text-gray-400">{ contractor.services.join(', ') }</div>
                </div>
                <div className="flex-auto ml-5">
                    { contractor.email && <div className="flex items-center text-sm text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                        { contractor.email }
                    </div> }
                    { contractor.phone && <div className="flex items-center text-sm mt-1 text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                        { contractor.phone }
                    </div> }
                </div>
                <div className="ml-auto flex-0 text-sm">
                    <button onClick={() => props.onClickEdit(contractor)} type="button" className="flex items-center text-gray-500 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                        bearbeiten
                    </button>
                    <button onClick={() => deleteContractor(contractor)} type="button" className="flex items-center text-red-400 hover:text-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        löschen
                    </button>
                </div>
            </div>
        </div>
    });

    return props.contractors.length > 0 ? conList : <div className="text-xl">Bisher wurden keine Dienstleister angelegt.</div>;
}

export default ContractorList