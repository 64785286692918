import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useApi from "../../../../services/api";
import { useEditor } from "../../editor/Editor";
import { getIcon } from './icons';

export const HafasSettings = (props) => {
    const [nearbyLocations, setNearbyLocations] = useState('loading');
    const [selectedLocations, setSelectedLocations] = useState(props.locations)
    const api = useApi();
    const { display } = useEditor();

    useEffect(() => {
        api.get(`data/${ display.id }/hafas`, {
            params: {
                type: 'nearby'
            }
        }).then(result => setNearbyLocations(result.data))
    }, []);

    useEffect(() => props.setProps({ locations: selectedLocations }), [selectedLocations])

    const locationList = nearbyLocations !== 'loading' && nearbyLocations.map(location => {
        const isSelected = selectedLocations.includes(location.id)
        
        return <div key={location.id} className="mt-2 p-1 bg-white shadow leading-tight flex rounded">
            <div className="p-2 bg-gray-200 flex items-center rounded">
                <input type="checkbox" className="rounded" value={location.id} checked={isSelected} onChange={e => {
                    if (selectedLocations.includes(location.id)) {
                        setSelectedLocations(selectedLocations.filter(l => l !== location.id))
                    } else {
                        setSelectedLocations([...selectedLocations, location.id]);
                    }
                }} />
            </div>
            <div className="flex-1 p-2 px-3 overflow-hidden">
                <div className="flex w-full items-center mb-2 whitespace-nowrap">
                    <div className="whitespace-nowrap font-medium text-ellipsis overflow-hidden pr-5">
                        { location.name }
                    </div>
                    <div className="ml-auto text-sm text-gray-500">
                        { location.distance } m
                    </div>
                </div>
                <div className="flex items-center">
                    { Object.keys(location.products).map(key => {
                        if (location.products[key]) {
                            return <div key={key} className="mr-2">
                                <img src={getIcon(key)} className="h-4 w-full"  />
                            </div>
                        }
                    }) }
                </div>
            </div>            
            
        </div>
    });

    return (<div>
        { nearbyLocations == 'loading' ?
        <div className="text-center text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-8 h-8 animate-spin mx-auto mb-3" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
            Stationen in der Umgebung werden geladen...
        </div>
        
        :
            
        <div>
            <div className="font-medium">Stationen in der Nähe</div>
            <div className="text-gray-600 text-sm mb-5">Nachfolgend sehen Sie die Stationen in der Nähe Ihres Displays. Bitte wählen Sie aus, welche Abfahrtszeiten Sie anzeigen möchten.</div>

            { locationList }
        </div>
        }        
    </div>)

}

HafasSettings.defaultProps = {
    locations: []
};