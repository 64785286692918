import { WeatherWidget } from "./WeatherWidget";
import { WeatherSettings } from "./WeatherSettings";

const module = {
    name: 'Wettervorhersage',
    description: 'Zeigt das aktuelle Wetter des Standorts an.',
    icon: 'test',
    properties: {
        location: null
    },
    widget: WeatherWidget,
    settings: WeatherSettings
}

export default module