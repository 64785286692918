import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios'
import Logo from '../assets/img/logo.png'

const API_BASE = process.env.REACT_APP_API_ENDPOINT + '/api/';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null)
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let localToken = localStorage.getItem('token');

        if (localToken && !user) {
            // validate user and token here
            axios.get(API_BASE + 'user', {
                headers: {
                    Authorization: 'Bearer ' + localToken
                }
            }).then(result => {
                setToken(localToken);
                setUser(result.data);
                setLoading(false);
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');
                    setLoading(false)
                }
            });
        } else {
            setLoading(false)
        }
    }, [user])
    
    const isAuthenticated = () => token && user;

    const login = (credentials) => new Promise((resolve, reject) => {
        axios.post(API_BASE + 'login', {
            ...credentials,
            device_name: 'test'
        }).then(result => {
            setToken(result.data.token);
            setUser(result.data.user);

            localStorage.setItem('token', result.data.token);

            resolve(user);
        }).catch(e => {
            reject(e);
        })
    })

    const logout = () => axios.post(API_BASE + 'logout', {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    const userHasPermission = (permission) => {
        if (!user) return false;

        // admins have all permissions
        if (user.role === 'admin') return true;

        // fake permissions for every other role
        const permissions = ['ads'];

        return permissions.includes(permission);
    }

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout, token, userHasPermission }}>
            {loading ? 
                <div className="w-screen h-screen flex items-center justify-center">
                    <img src={Logo} alt="Portado Logo" className="animate-bounce w-56 h-auto mb-20" />
                </div>
            : children}
        </AuthContext.Provider>
    );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth }