import React, { useEffect, useState } from "react";
import { useEditor } from "./Editor";
import { useDrop } from 'react-dnd';
import classNames from "classnames";

const Slot = (props) => {
    const { widgets, setWidgets, selected, setSelected, modules } = useEditor();
    const [renderWidget, setRenderWidget] = useState(false);
    const [isDropover, setIsDropover] = useState(false)

    const [collectedProps, drop] = useDrop(() => ({
        accept: Object.keys(modules),
        drop: (item) => {

            widgets[props.position] = {
                widget: item.id,
                props: item.properties
            }
            
            setWidgets({ ...widgets })
            setSelected(props.position)
        },
        collect(monitor) {
            setIsDropover(monitor.canDrop() && monitor.isOver())
        }
    }))

    const select = (e) => {
        e.stopPropagation();

        if (widgets[props.position]) {
            setSelected(props.position)
        } else {
            setSelected(null)
        }
    }

    useEffect(() => {
        if (widgets[props.position]) {
            let widget = widgets[props.position];

            let rendered = React.createElement(
                modules[widget.widget].widget,
                {
                    ...widget.props,
                    position: props.position
                }
            )

            setRenderWidget(rendered);
        } else {
            setRenderWidget(false);
        }
        
    }, [widgets, modules, props.position]);

    const slotIsSelected = () => props.position === selected;

    const classes = classNames(
        props.className, 
        'transition-all duration-200 overflow-hidden',
        {
            'shadow-2xl shadow-slate-500/70 scale-105 z-20 rounded-lg bg-white': slotIsSelected(),
            'ring-4 ring-green-400 ring-offset-4 ring-offset-green-100 z-20': isDropover
        }
    )

    return (
        <div ref={drop} onClick={select} className={classes}>
            { renderWidget || <div className="w-full h-full flex justify-center items-center">
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.5} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> */}
            </div> }
        </div>
    )
}

export { Slot }