import ContactWidget from './ContactWidget'
import ContactSettings from './ContactSettings'
 
const module = {
    name: 'Ansprechpartner',
    description: 'Zeigt die wichtigsten Ansprechpartner des Gebäudes an.',
    icon: 'test',
    properties: {
        persons: [
            {
                name: 'Max Mustermann'
            }
        ],
        emergencyContacts: ''
    },
    widget: ContactWidget,
    settings: ContactSettings
}

export default module;