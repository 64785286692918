import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from '../assets/img/logo.png';
import { useAuth } from "../contexts/AuthContext";

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
    const sidebar = useRef(null);
    const trigger = useRef(null);
    const location = useLocation();
    const { userHasPermission } = useAuth();

    const isEdit = location.pathname.includes('/edit');

    return (
        <>
            {/* Sidebar backdrop (mobile only) */}
            <div onClick={() => setSidebarOpen(false)} className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>

            {/* Sidebar */}
            <aside
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute lg:shadow-2xl z-40 left-0 top-0 lg:translate-x-0 transform h-screen overflow-hidden lg:overflow-y-auto w-80 shrink-0 bg-white transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-80'} ${isEdit ? 'lg:!w-24 lg:hover:!w-80' : ''}`}
            >
                <NavLink to="/" className="border-b border-gray-200 block px-10 py-5 mb-10 h-20">
                    <img src={Logo} alt="Portado" className="w-52 max-w-none h-auto block" />    
                </NavLink>

                <nav className="flex flex-col text-xl">
                    { userHasPermission('displays') && <NavLink to="/" className={({isActive}) => `px-10 py-5 flex items-center hover:text-brand-500 ${isActive ? 'text-brand bg-gray-100' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 flex-none mr-8"><path fill="none" d="M0 0h24v24H0z"/><path d="M22 9.999V20a1 1 0 0 1-1 1h-8V9.999h9zm-11 6V21H3a1 1 0 0 1-1-1v-4.001h9zM11 3v10.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v3.999h-9V3h8z"/></svg>
                        Displays
                    </NavLink> }
                    { userHasPermission('services') && <NavLink to="/services" className={({isActive}) => `px-10 py-5 flex items-center hover:text-brand-500 ${isActive ? 'text-brand bg-gray-100' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 flex-none mr-8"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.161 4.469a6.5 6.5 0 0 1 8.84-.328 6.5 6.5 0 0 1 9.178 9.154l-7.765 7.79a2 2 0 0 1-2.719.102l-.11-.101-7.764-7.791a6.5 6.5 0 0 1 .34-8.826zm1.414 1.414a4.5 4.5 0 0 0-.146 6.21l.146.154L12 19.672l5.303-5.304-3.535-3.535-1.06 1.06a3 3 0 1 1-4.244-4.242l2.102-2.103a4.501 4.501 0 0 0-5.837.189l-.154.146zm8.486 2.828a1 1 0 0 1 1.414 0l4.242 4.242.708-.706a4.5 4.5 0 0 0-6.211-6.51l-.153.146-3.182 3.182a1 1 0 0 0-.078 1.327l.078.087a1 1 0 0 0 1.327.078l.087-.078 1.768-1.768z"/></svg>
                        Services
                    </NavLink> }
                    { userHasPermission('ads') && <NavLink to="/ads" className={({isActive}) => `px-10 py-5 flex items-center hover:text-brand-500 ${isActive ? 'text-brand bg-gray-100' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 flex-none mr-8"><path fill="none" d="M0 0h24v24H0z"/><path d="M4.929 2.929l1.414 1.414A7.975 7.975 0 0 0 4 10c0 2.21.895 4.21 2.343 5.657L4.93 17.07A9.969 9.969 0 0 1 2 10a9.969 9.969 0 0 1 2.929-7.071zm14.142 0A9.969 9.969 0 0 1 22 10a9.969 9.969 0 0 1-2.929 7.071l-1.414-1.414A7.975 7.975 0 0 0 20 10c0-2.21-.895-4.21-2.343-5.657L19.07 2.93zM7.757 5.757l1.415 1.415A3.987 3.987 0 0 0 8 10c0 1.105.448 2.105 1.172 2.828l-1.415 1.415A5.981 5.981 0 0 1 6 10c0-1.657.672-3.157 1.757-4.243zm8.486 0A5.981 5.981 0 0 1 18 10a5.981 5.981 0 0 1-1.757 4.243l-1.415-1.415A3.987 3.987 0 0 0 16 10a3.987 3.987 0 0 0-1.172-2.828l1.415-1.415zM12 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-1 2h2v8h-2v-8z"/></svg>
                        Werbeanzeigen
                    </NavLink> }
                    { userHasPermission('games') && <NavLink to="/games" className={({isActive}) => `px-10 py-5 flex items-center hover:text-brand-500 ${isActive ? 'text-brand bg-gray-100' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="fill-current w-7 h-7 flex-none mr-8" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2a9.98 9.98 0 0 1 7.743 3.671L13.414 12l6.329 6.329A9.98 9.98 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2zm0 2a8 8 0 1 0 4.697 14.477l.208-.157-6.32-6.32 6.32-6.321-.208-.156a7.964 7.964 0 0 0-4.394-1.517L12 4zm0 1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"/></svg>
                        Spiele
                    </NavLink> }
                    { userHasPermission('settings') && <NavLink to="/settings" className={({isActive}) => `px-10 py-5 flex items-center hover:text-brand-500 ${isActive ? 'text-brand bg-gray-100' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 flex-none mr-8"><path fill="none" d="M0 0h24v24H0z"/><path d="M5.33 3.271a3.5 3.5 0 0 1 4.472 4.474L20.647 18.59l-2.122 2.121L7.68 9.867a3.5 3.5 0 0 1-4.472-4.474L5.444 7.63a1.5 1.5 0 1 0 2.121-2.121L5.329 3.27zm10.367 1.884l3.182-1.768 1.414 1.414-1.768 3.182-1.768.354-2.12 2.121-1.415-1.414 2.121-2.121.354-1.768zm-7.071 7.778l2.121 2.122-4.95 4.95A1.5 1.5 0 0 1 3.58 17.99l.097-.107 4.95-4.95z"/></svg>
                        Einstellungen
                    </NavLink> }
                </nav>

            </aside>
        </>
    )
}

export default Sidebar;