import React from "react";
import { useEditor } from "./Editor";

const Viewport = (props) => {
    const { setSelected } = useEditor();

    return (<div className={props.className} onClick={e => setSelected(null)}>
        { props.children }
    </div>)
}

export { Viewport };