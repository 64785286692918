import React, { useEffect } from "react";
import { useState } from "react";
import useApi from "../../services/api";
import ContractorList from "./ContractorList";
import ServicesSlider from "./ServicesSlider";
import ContractorModal from "./ContractorModal";

const ServicesIndex = () => {
    const [contractors, setContractors] = useState('loading');
    const [contractorModalOpen, setContractorModalOpen] = useState(false)
    const [services, setServices] = useState('loading')
    const [editableContractor, setEditableContractor] = useState(ContractorModal.defaultProps.contractor)
    const api = useApi();

    useEffect(() => {
        api.get('contractors').then(result => setContractors(result.data))
        api.get('services').then(result => setServices(result.data));
    }, []);

    const createContractor = () => {
        setEditableContractor({
            ...ContractorModal.defaultProps.contractor,
            pin: Math.round(Math.random() * 100000)
        });
        setContractorModalOpen(true);
    };

    const editContractor = contractor => {
        setEditableContractor(contractor);
        setContractorModalOpen(true);
    };

    const deleteContractor = contractor => {
        setContractors(contractors.filter(c => c.id !== contractor.id));

        api.delete(`contractors/${contractor.id}`);
    }

    const saveContractor = contractor => {
        setContractorModalOpen(false);

        if (contractor.id) {
            api.patch(`contractors/${ contractor.id }`, contractor).then(({data}) => {
                let index = contractors.findIndex(a => a.id === data.id);
                contractors[index] = {...data};
                setContractors([...contractors]);
            })
        } else {
            delete contractor.id;
            api.post('contractors', contractor).then(result => setContractors([...contractors, result.data]))
        }
    };

    return (
        <div className="px-6 lg:px-8 py-8 xl:pt-20 w-full max-w-7xl mx-auto">
            <ContractorModal isOpen={contractorModalOpen} contractor={editableContractor} onAbort={() => setContractorModalOpen(false)} onSave={saveContractor} />

            <div className="lg:flex items-center mb-3">
                <div className="w-full">
                    <div className="text-brand-500 text-4xl mb-2">Service Übersicht</div>
                    <p className="text-gray-500 mb-5">Auf Ihren Displays können Ihre Vertragspartner die unterschiedlichen Aufträge quittieren, die in Ihren Hauseingängen anfallen. Hierfür legen Sie rechts bitte zunächst Ihre Servicepartner an. Vergeben Sie jedem Partner einen PIN, mit dem er sich auf den Displays identifizieren kann.</p>
                </div>
                <button type="button" className="border flex-none lg:ml-20 border-gray-400 px-5 py-3 rounded hover:bg-gray-400 hover:text-white transition-all" onClick={createContractor}>Neuer Servicepartner</button>             
            </div>
            
            
            
            { services === 'loading' ?
                <div className="flex items-center text-gray-400 text-xt justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mr-5 w-10 h-10 text-brand-300 animate-spin" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
                    Dienstleister werden geladen...
                </div>
            : <div>
                <ContractorList contractors={contractors} onClickEdit={contractor => editContractor(contractor)} onClickRemove={contractor => deleteContractor(contractor)} />
            </div> }

            <h2 className="text-3xl mt-20 mb-10 text-center text-brand-500">Erfolgte Services</h2>
            <ServicesSlider className="mb-32"></ServicesSlider>
        </div>
    );

}; 

export default ServicesIndex;