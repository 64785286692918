import React from "react";
import { useMediaLibrary } from ".";
import useApi from "../../services/api";

const Toolbar = () => {
    
    const { setNewCollectionName, setViewType, viewType, selectedMedia, setSelectedMedia, media, setMedia, uploadFile } = useMediaLibrary();
    const api = useApi();

    const deleteFile = () => {
        if (window.confirm('Möchten Sie diese Datei wirklich löschen?')) {
            setMedia(media.filter(m => m.id !== selectedMedia.id));
            setSelectedMedia(null);
            api.delete('media/' + selectedMedia.id);
        }
    }

    const handleUpload = () => {
        const input = document.createElement('input')
        document.body.append(input);

        input.type = 'file';
        input.style.display = 'none';
        input.onchange = () => {
            
            for (let file of input.files) {
                uploadFile(file);
            }

        }
        
        input.click();
        // input.remove();
    }

    return (
        <nav className="media-toolbar text-sm flex items-center w-full h-14 overflow-hidden relative z-10 text-slate-700 shadow-lg">
            <div className="w-60 bg-gray-100 p-3">
                <button className="inline-flex items-center py-2 px-5 rounded hover:bg-slate-200 transition-all" onClick={() => setNewCollectionName('')}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                    </svg>
                    Neue Sammlung
                </button>
            </div>
            <div className="p-3 flex-1 flex w-full">
                <button onClick={handleUpload} className="inline-flex items-center py-2 px-3 rounded hover:bg-slate-200 transition-all active:shadow-inner active:bg-slate-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    </svg>
                    Datei hochladen
                </button>
                { selectedMedia !== null && <>
                    <button onClick={deleteFile} className="inline-flex items-center py-2 px-3 ml-1 rounded hover:bg-slate-200 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        Datei löschen
                    </button>
                    <button className="inline-flex items-center py-2 px-3 ml-1 rounded hover:bg-slate-200 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        Datei umbenennen
                    </button>
                </>}
                <button className="inline-flex items-center py-2 px-5 ml-auto rounded hover:bg-slate-200 transition-all" onClick={() => setViewType(viewType === 'grid' ? 'list' : 'grid')}>
                    { viewType === 'grid' ? <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                    </svg> }
                </button>
            </div>
        </nav>
    )
}

export default Toolbar