import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import useApi from "../../services/api";


const GamesIndex = () => {
    const api = useApi();
    const [games, setGames] = useState([]);

    useEffect(() => {
        api.get('games').then(res => {
            setGames(res.data.data);
        });
    }, []);

    const gamesList = games.map(game => {

        return <div className="w-full bg-white shadow-lg rounded-md border p-5 flex items-center overflow-hidden" key={game.id}>
            <div className="bg-slate-500 w-96 mr-12 rounded overflow-hidden" style={{aspectRatio: '16/12'}}>
                <img src={require('../../assets/games/' + game.slug + '.jpg')} alt={game.slug} className="w-full h-full object-cover" />
            </div>

            <div>
                <h2 className='text-3xl font-medium text-brand-500'>Elfmeterschießen</h2>
                <p className="text-gray-500 text-lg">Wer ist der beste Schütze und überwindet in 15 Versuchen den Torwart?</p>

                <div className="flex gap-10 pt-7 mt-7 border-gray-300">
                    <div className='text-center w-44 text-brand-500  border-brand-200 border py-2 rounded'>
                        <div className="text-brand-500 text-lg font-bold">Highscore:</div>
                        {game.highscore && <><div className="text-4xl font-medium leading-none rounded">{ (game.highscore.score + "").padStart(5, "0") }</div>
                        <div className="leading-tight">{game.highscore.display_name}</div></>}
                        { game.highscore == null && <div className="text-4xl font-medium  leading-none rounded">0000</div>}
                    </div>
                    <div className="border-r border-gray-300 self-stretch w-0"></div>
                    <div className="flex-1">
                        <button onClick={() => {
                            const baseURL = process.env.NODE_ENV === 'production' ?
                                'https://www.portado.de/games/' :
                                'https://ds-backend.ribal-webentwicklung.de/games/';
                            navigator.clipboard.writeText(baseURL + game.id);
                        }} type='button' className="w-full bg-gray-100 py-2 px-4 text-lg flex items-center justify-center text-gray-500 rounded-md hover:bg-brand-500 hover:text-white hover:shadow-xl transition-all active:bg-brand-600">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                            </svg>
                            Spiele-Link kopieren
                        </button>

                        <button onClick={() => resetGameScores(game)} type='button' className="w-full border border-gray-200 mt-2 py-2 px-4 text-lg flex items-center justify-center text-gray-500 rounded-md hover:bg-brand-500 hover:text-white hover:shadow-xl transition-all active:bg-brand-600">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            Punktestände zurücksetzen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    });

    const resetGameScores = (game) => {
        game.highscore = null;

        // update games variable
        setGames([...games]);

        api.post('games/' + game.id + '/reset').then(res => {

        });
    };

    return <div>
        <div className="px-6 lg:px-8 py-8 xl:pt-20 w-full max-w-6xl mx-auto">
            <div className="flex items-center">
                <div>
                    <div className="text-brand-500 text-5xl mb-5">Spiele</div>
                    <div className="text-slate-500 text-lg mb-10">
                        Lassen Sie Ihre Mieter Spiele gegeneinander spielen. Nachfolgend finden Sie alle aktiven Spiele, sowie deren aktuelle Punktestände. Um ein Spiel auf einem Display anzubieten, kopieren Sie den Spiel-Link und fügen Sie ihn beispielsweise als Verlinkung eines Bildes ein.
                    </div>
                </div>
                <button type='button' className="ml-20 whitespace-nowrap text-lg text-brand-500 border border-brand-400 py-3 px-5 rounded-md">
                    Neues Spiel anlegen
                </button>
            </div>
        
            { gamesList }
        </div>
    </div>
};

export default GamesIndex;