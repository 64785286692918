import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./assets/scss/index.scss";
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

let container = (
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <App />
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

if (process.env.NODE_ENV === "production") {
    Bugsnag.start({
        apiKey: 'e4b7986e3f9a01e2ce0cef4e44db9fa3',
        plugins: [new BugsnagPluginReact()]
    })

    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

    container = <ErrorBoundary>{ container }</ErrorBoundary>
}

ReactDOM.render(container, document.getElementById('root'));