import React from "react";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import useApi from "../../../../services/api";
import { useEditor } from "../../editor/Editor";
import { format } from "date-fns";
import classNames from "classnames";
import { getIcon } from "./icons";

const HafasWidget = props => {
    const [departures, setDepartures] = useState('loading')
    const { display } = useEditor();
    const api = useApi();

    useEffect(() => {
        setDepartures('loading');

        if (props.locations.length == 0) {
            return;
        }

        api.get(`data/${ display.id }/hafas`, {
            params: {
                type: 'departures',
                locations: props.locations
            }
        }).then(result => setDepartures(result.data.departures))
    }, [props.locations]);

    const inSidebar = useMemo(() => ['l1', 'l2', 'r1', 'r2'].includes(props.position), [props.position]);

    if (props.locations == null || props.locations.length == 0) {
        return <div className="w-full h-full flex justify-center text-center px-in-10 items-center text-in-xl">
            Bitte wählen Sie eine Station in den Widget Einstellungen aus, dessen Abfahrtszeiten Sie anzeigen möchten.
        </div>;
    }

    if (departures === "loading") {
        return <div className="w-full h-full flex justify-center items-center text-in-xl">Fahrplan wird geladen...</div>;
    }

    const departuresList = departures.map((departure, index) => {

        return <div key={index} className={`py-in-2 flex items-center w-full overflow-hidden break-inside-avoid ${ index > 0 && 'border-gray-200' } ${!inSidebar && 'px-in-10'}`}>
            <div className="mr-in-4 leading-none py-in-2 px-in-2 bg-gray-200 w-in-16 rounded relative">
                <div className="text-in-xl font-semibold text-gray-600">{ format(new Date(departure.when), 'HH:mm') }</div>
                { departure.delay !== 0 && <div className={classNames(
                    'absolute px-in-1 leading-tight whitespace-nowrap rounded-sm text-in-xs -bottom-1 -right-in-2',
                    {
                        'bg-red-300': departure.delay > 0,
                        'bg-green-300': departure.delay < 0
                    }
                )}>{ departure.delay > 0 ? '+' : '-' } { Math.abs(departure.delay / 60) } Min.</div> }
            </div>
            <div className="flex-1 overflow-hidden leading-tight">
                <div className="text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis mb-in-1">{ departure.station }</div>
                <div className="flex items-center font-medium whitespace-nowrap overflow-hidden">
                    <img src={ getIcon(departure.product) } className="h-in-4 w-auto mr-in-2" />
                    <div className={classNames(
                        "mr-in-2 font-bold",
                        // {
                        //     'bg-red-600': departure.product === 'tram'
                        // }
                    )}>{ departure.line }</div>
                    <div className="mr-in-2">&rarr;</div>
                    <div className="flex-1 overflow-hidden text-ellipsis">{ departure.direction }</div>
                </div>
            </div>
        </div>
    });

    return (<div className="p-in-5">
        <div className={`text-in-2xl mb-in-2 ${!inSidebar && 'text-center'}`} style={{ color: '#0069af' }}>Abfahrtszeiten in der Nähe</div>
        { inSidebar ? departuresList : <div className="columns-2">{ departuresList }</div> }
    </div>)

}

export default HafasWidget;