import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "../../services/api";
import { format as formatDate } from 'date-fns';
import { de } from 'date-fns/locale';
import { motion } from "framer-motion";

import { Editor } from './editor/Editor'
import { Viewport } from './editor/Viewport'
import { Slot } from './editor/Slot'
import { Toolbox } from "./editor/Toolbox";
import { Settings } from "./editor/Settings";

const EditDisplay = () => {
    const [display, setDisplay] = useState(null)
    const [widgets, setWidgets] = useState(null)
    const api = useApi()
    const params = useParams();
    const [time, setTime] = useState(new Date())

    useEffect(() => {
        window.setTimeout(() => {
            api.get('displays/' + params.displayId).then(result => {
                setDisplay(result.data)
            })

            api.get(`displays/${params.displayId}/widgets`).then(result => {
                setWidgets(result.data.data)
            })
        }, 0);

        setInterval(() => setTime(new Date()), 1000);
    }, [])

    const greeting = () => {
        if (time.getHours() >= 18) {
            return "Guten Abend!";
        } else if (time.getHours() >= 12) {
            return "Guten Tag!";
        } else if (time.getHours() > 6) {
            return "Guten Morgen!";
        } else {
            return "Gute Nacht!";
        }
    }

    const saveDisplay = () => {
        api.post(`displays/${params.displayId}/widgets`, { widgets });
    }

    return (
        <>
        { display && widgets ? 
            <Editor display={display} widgets={widgets} setWidgets={setWidgets}>
                <div className="w-full relative flex-1 overflow-hidden">
                    <Viewport className="h-full">
                        <motion.div className="viewport h-full content flex-auto flex justify-center items-center p-8 relative z-0" style={{ paddingLeft: '25% '}} initial={{ translateX: '-12.5%'}} animate={{ translateX: '-25%' }} transition={{ delay: .9, duration: .75, type: 'spring' }}>
                            <motion.div className="w-11/12 relative bg-gradient-to-br mt-10 from-gray-600 to-gray-800 p-3 pb-7 rounded-lg shadow-2xl" animate={{ opacity: 1, translateY: 0 }} initial={{ opacity: 0, translateY: 100}} transition={{ type: 'spring' }}>
                                <div className="display-canvas w-full h-0 relative rounded">
                                    <div className="display-canvas-inner absolute left-0 top-0 w-full h-full flex flex-col">
                                        <header className="display-header flex shadow bg-white z-10 rounded-t" style={{ backgroundColor: '#0069af', color: '#fff'}}>
                                            <div className="logo w-1/3">SWB</div>
                                            <div className="date text-in-2xl leading-none font-light w-1/3 text-center">{ formatDate(time, 'EEEE, d. MMMM yyyy – HH:mm', {locale: de}) }</div>
                                            <div className="greeting w-1/3 text-right">{ greeting() }</div>
                                        </header>
                                        <main className="flex-auto flex z-10">
                                            <div className="sidebar sidebar-left w-1/5 flex-none border-r flex flex-col h-full bg-slate-100">
                                                <Slot position="l1" className="h-1/2 overflow-hidden"></Slot>
                                                <Slot position="l2" className="h-1/2 overflow-hidden"></Slot>
                                            </div>
                                            <div className="flex-auto main-content flex flex-col bg-white">
                                                <div style={{ paddingTop: '56.25%' }} className="relative">
                                                    <Slot position="m1" className="absolute left-0 top-0 h-full w-full"></Slot>
                                                </div>
                                                
                                                <Slot position="m2" className="flex-1"></Slot>
                                            </div>
                                            <div className="sidebar sidebar-right w-1/5 flex-none border-l flex flex-col bg-slate-100">
                                                <Slot position="r1" className="h-1/2 overflow-hidden"></Slot>
                                                <Slot position="r2" className="h-1/2 overflow-hidden"></Slot>
                                            </div>
                                        </main>
                                        <footer className="display-footer bg-white shadow rounded-b border-t z-0" style={{ backgroundColor: '#0069af', color: '#fff'}}>
                                            &nbsp;
                                        </footer>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </Viewport>
                    <motion.div className="sidebar absolute top-0 right-0 h-full w-3/12 bg-gray-50 shadow-lg overflow-hidden" initial={{ translateX: '100%' }} animate={{ translateX: '0%' }} transition={{ delay: .75, type: 'spring', duration: .75 }}>
                        { display && (<>

                            <div className="p-8 border-b bg-white border-slate-200">
                                <div className="text-xl mb-1 font-medium">Display bearbeiten</div>
                                <div className="text-sm text-slate-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tortor tortor, vehicula non molestie ut, vulputate.</div>
                            </div>

                            <div className="px-8 py-4 border-b border-slate-200">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-3 text-slate-400" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M2 4c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 18V4zm2 1v12h16V5H4zm1 15h14v2H5v-2z"/></svg>
                                    <h3 className="font-medium">Display-Eigenschaften</h3>
                                    <svg onClick={() => {
                                        if (window.confirm('Display löschen?')) {
                                            api.delete(`displays/${ display.id }`);

                                        }
                                    }} xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                    </svg>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                    </svg> */}
                                </div>

                                <div className="mb-4 mt-6">
                                    <div className="mb-1">Display Name:</div>
                                    <input type="text" className="w-full rounded p-2 text-slate-600 bg-slate-200 shadow-inner border-0" value={display.name} onChange={e => setDisplay({...display, name: e.target.value})} />
                                </div>

                                {/* <div className="mb-4">
                                    <div className="mb-1">Display Adresse:</div>
                                    <input type="text" className="w-full rounded p-2 bg-slate-200 shadow-inner border-0" value={display.address} onChange={e => setDisplay({...display, name: e.target.value})} />
                                </div> */}

                                <div className="mb-2 flex items-center">
                                    <div className="w-44">Display Status:</div>
                                    { display.online ? (
                                        <div className="text-green-600"><span className="inline-block w-3 h-3 mr-2 bg-green-600 rounded-full"></span> in Betrieb</div>
                                    ) : (
                                        <div className="text-red-500 flex items-center"><span className="w-3 h-3 mr-2 bg-red-500 rounded-full"></span> ist nicht in Betrieb</div>
                                    ) }
                                </div>

                                <div className="flex items-center">
                                    <div className="w-44">Installations-Code:</div>
                                    <div className="font-mono font-medium text-lg">{ display.code }</div>
                                </div>

                                {/* <button type="submit" className="w-full mt-2 p-3 rounded bg-slate-600 text-white" onClick={saveDisplay}>Save</button> */}
                                {/* <button type="button" className="w-full mt-2 py-1 rounded border border-red-400 text-red-400" onClick={saveDisplay}>Display löschen</button> */}
                            </div>

                            <div className="px-8 py-4 border-b border-slate-200">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-3 text-slate-400" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M2 4c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 18V4zm2 1v12h16V5H4zm1 15h14v2H5v-2z"/></svg>
                                    <h3 className="font-medium">Widgets</h3>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                    </svg>
                                </div>

                                <div className="mt-6">
                                    <Toolbox />
                                </div>

                            </div>
                            
                            {/* <div className="p-6">
                                <div className="text-lg font-medium">Widgets</div>
                                <Toolbox />
                            </div>                             */}

                        </>)}
                        
                        {/* <pre>{ JSON.stringify(display, false, 2) }</pre> */}

                        
                        <Settings />
                    </motion.div>
                </div>
            </Editor>
        : <div className="w-full flex-1 flex items-center justify-center text-4xl">
                lade
        </div> }
        </>
    )
}

export default EditDisplay