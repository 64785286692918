import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { motion } from 'framer-motion'
import classNames from "classnames";
import useApi from "../../services/api";

const ContractorModal = (props) => {
    const [contractor, setContractor] = useState(props.contractor)
    const api = useApi();

    const windowVariants = {
        closed: { translateY: 100, opacity: 0 },
        open: { translateY: 0, opacity: 1 }
    }

    useEffect(() => setContractor(props.contractor), [props.contractor]);

    const servicesList = contractor.services.map((service, index) => (
        <div className="flex items-center mt-2" key={index}>
            <input type="text" className="w-full border-gray-200 rounded service-input" value={service} onChange={e => {
                contractor.services[index] = e.target.value;
                setContractor({ ...contractor, services: contractor.services });
            }} />
            <button type="button" onClick={() => {
                contractor.services.splice(index, 1);

                setContractor({
                    ...contractor,
                    services: contractor.services
                })
            }} className="text-red-500 ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                </svg>
            </button>
        </div>
    ));

    return props.isOpen && createPortal(
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <motion.div className="absolute w-full h-full bg-gray-400 bg-opacity-50" initial={{ opacity: 0 }} animate={{ opacity: 1}}></motion.div>
            <motion.div className="bg-white w-11/12 mx-auto rounded-lg shadow-xl z-50 overflow-y-auto md:max-w-5xl" variants={windowVariants} animate={props.isOpen ? 'open' : 'closed'}>
                <div className="flex">
                    <div className="w-4/12 flex-none bg-gray-100 p-8">
                        <h3 className="text-2xl font-medium">{ contractor.id ? 'Dienstleister bearbeiten' : 'Dienstleister erstellen' }</h3>
                        <p className="text-sm mt-4 text-gray-500">Geben Sie bitte den Namen und, wenn benötigt, die Kontaktinformationen zu Ihrem Dienstleister ein. Anschließend legen Sie fest, welche Leistungen dieser erbringt. Bei der Quittierung hat er diese Möglichkeiten zur Auswahl.</p>
                    </div>
                    <form onSubmit={e => {
                        e.preventDefault();
                        props.onSave(contractor);
                    }} className="flex-auto p-8 xl:p-10">
                        <div className="flex items-center mb-4">
                            <div className="w-9/12">
                                <label className="block mb-1 text-sm text-gray-500">Name des Dienstleisters:</label>
                                <input type="text" className="text-xl py-3 w-full rounded border-gray-200" value={contractor.name} onChange={(e) => setContractor({...contractor, name: e.target.value})} />
                            </div>
                            <div className="flex-1 flex-none ml-3">
                                <label className="block mb-1 text-sm text-gray-500">PIN:</label>
                                <input type="number" step="1" min={10000} max={99999} className="text-xl py-3 w-full rounded border-gray-200 hide-spinner" value={contractor.pin} onChange={(e) => setContractor({...contractor, pin: e.target.value})} />
                            </div>
                        </div>
                        <div className="flex mb-4">
                            <div className="flex-auto mr-2">
                                <label className="block mb-1 text-sm text-gray-500">Telefonnummer (intern, optional):</label>
                                <input type="tel" className="w-full p-2 rounded border-gray-200" value={contractor.phone} onChange={e => setContractor({...contractor, phone: e.target.value})} />
                            </div>
                            <div className="flex-auto ml-2">
                                <label className="block mb-1 text-sm text-gray-500">E-Mail (intern, optional):</label>
                                <input type="email" className="w-full p-2 rounded border-gray-200" value={contractor.email} onChange={e => setContractor({...contractor, email: e.target.value})} />
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="flex items-center mb-2">
                                <div className="text-gray-500">Serviceleistungen:</div>
                                <button type="button" className="text-gray-600 ml-auto" onClick={() => {
                                    setContractor({
                                        ...contractor,
                                        services: [...contractor.services, '']
                                    });

                                    window.setTimeout(() => document.querySelectorAll('input.service-input')[document.querySelectorAll('input.service-input').length - 1].focus(), 100);
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            { contractor.services.length > 0 ? servicesList : <div className="text-center border-gray-200 border rounded text-gray-500 p-3">
                                Klicken Sie auf das Plus-Symbol, um eine neue Leistung hinzuzufügen.
                            </div> }
                        </div>
                        <div className="flex items-center mt-10">
                            <button type="button" className="border border-gray-200 px-5 py-2 rounded" onClick={() => props.onAbort()}>Abbrechen</button>
                            <button type="submit" className={classNames(
                                'border border-gray-500 text-gray-700 px-10 py-3 rounded ml-auto hover:bg-gray-500 hover:text-white transition-all',
                                { 'opacity-50 pointer-events-none': !contractor.name || !contractor.pin || contractor.services.length == 0 }
                            )} disabled={!contractor.name  || !contractor.pin || contractor.services.length == 0}>Speichern</button>
                        </div>
                    </form>
                </div>
            </motion.div>
        </div>
    , document.body);
}

ContractorModal.defaultProps = {
    contractor: {
        id: null,
        name: '',
        email: '',
        phone: '',
        services: [],
        pin: ''
    },
    onAbort: () => true,
    onSave: () => true
}

export default ContractorModal