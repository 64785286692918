import React, { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Sidebar from "./Sidebar";
import Header from './Header';

const AppLayout = () => {
    const { user } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();

    if (!user) {
        return <Navigate to="/login" />;
    }

    const isEdit = location.pathname.includes('/edit');

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className={`relative lg:ml-80 flex flex-col flex-1 overflow-y-auto overflow-x-hidden ${isEdit ? 'lg:!ml-24' : ''}`}>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Outlet />
            </div>
        </div>
    )
}

export default AppLayout