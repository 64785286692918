import React from "react";
import classNames from "classnames";
import { useMediaLibrary } from ".";
import { useDrag } from "react-dnd";
import { motion } from "framer-motion";

const MediaGridItem = props => {
    const { selectedMedia, setSelectedMedia, media, setMedia } = useMediaLibrary();
    const { item } = props;
    const isSelected = selectedMedia && selectedMedia.id === item.id;

    const selectMedia = (e, item) => {
        e.stopPropagation();
        setSelectedMedia(item)
    }

    const [{}, drag] = useDrag(() => ({
        type: 'media',
        item
    }))

    const itemVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        show: { opacity: 1, scale: 1 }
    }
      

    const isImage = item.mime && item.mime.search(/^image\/(jpg|jpeg|svg|bmp|png)$/) !== -1;

    return (item.uploading ?
        <div className="p-2 bg-gray-50 text-center animate-pulse">
            <div className="w-full aspect-square mb-2 rounded flex justify-center items-center">
                <div className="w-10/12 h-2 rounded-full bg-gray-200 overflow-hidden">
                    <div className="h-full bg-gray-600" style={{ width: ((item.uploaded / item.size) * 100) + '%' }}></div>
                </div>
            </div>
            <div className="text-sm line-clamp-2 break-words">{ item.name }</div>
        </div>
    :
        <motion.div variants={itemVariants} ref={drag} className={classNames({
                'p-2 border rounded cursor-pointer text-center hover:bg-gray-50': true,
                'border-gray-300': isSelected,
                'border-transparent': !isSelected
            })} onClick={e => selectMedia(e, item)}>
            <div className="w-full h-0 mb-2 bg-gray-100 rounded relative" style={{paddingTop: '100%'}}>
                <div className="absolute left-0 top-0 w-full h-full">
                    { isImage ?
                        <img src={`${ process.env.REACT_APP_API_ENDPOINT }/images/${ item.id }/thumb/150x150`} className="w-full h-auto rounded" />
                    :
                        <div>not an image</div>
                    }
                </div>
            </div>
            <div className="text-sm leading-tight line-clamp-2 break-words">{ item.name }</div>
        </motion.div>
    )
}

export default MediaGridItem