import React from "react";
import { useEffect } from "react";
import HafasWidget from "./HafasWidget";
import { HafasSettings } from "./HafasSettings";

export default module = {
    name: 'Fahrplan',
    description: 'Damit Ihre Mieter schneller ans Ziel kommen – zeigen Sie den aktuellen Fahrplan von S-Bahn, Bus oder Straßenbahn.',
    icon: 'test',
    properties: {
        locations: []
    },
    widget: HafasWidget,
    settings: HafasSettings
};