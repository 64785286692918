import l1 from './l1.svg';
import l2 from './l2.svg';
import r1 from './r1.svg';
import r2 from './r2.svg';
import m1 from './m1.svg';
import m2 from './m2.svg';
import modal from './modal.svg';

export default {
    l1,
    l2,
    m1,
    m2,
    r1,
    r2,
    modal
}