import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import useApi from "../../../../services/api";
import { useEditor } from "../../editor/Editor";
import { formatDistanceToNow } from "date-fns";
import { de } from 'date-fns/locale';

const FacebookWidget = props => {
    const [posts, setPosts] = useState('loading');
    const api = useApi();
    const { display } = useEditor();

    useEffect(() => {
        api.get(`data/${ display.id }/facebook`, {
            pageId: 'Swb.Schoenebeck'
        }).then(result => setPosts(result.data.posts))
    }, []);

    const inSidebar = useMemo(() => ['l1', 'l2', 'r1', 'r2'].includes(props.position), [props.position]);

    if (posts === 'loading') {
        return <div className="w-full h-full flex justify-center items-center text-in-xl">Lade Facebook Posts</div>
    }

    const postsList = posts.map((post, index) => <div className={`${ inSidebar && 'flex items-center mb-in-2'}`}  key={index}>
        <div className={`${ inSidebar ? 'w-3/12' : 'w-6/12 mb-in-3'} mx-auto flex-none`}>
            <div className="bg-slate-300 rounded w-full relative overflow-hidden" style={{paddingTop: inSidebar ? '100%' : '75%'}}>
                { post.image && <img src={post.image} width="200" height="200" className="absolute left-0 top-0 w-full h-full object-cover" /> }
            </div>
        </div>
        <div className={`flex-1 ${ inSidebar ? 'ml-in-4' : 'text-center'}`}>
            <div className="line-clamp-2 leading-tight">{ post.message }</div>
            <div className="text-gray-500">{ formatDistanceToNow(new Date(post.created_at), { locale: de, addSuffix: true }) }</div>
        </div>
    </div>);

    return <div className="p-in-5">
        <div className={`text-in-2xl ${ inSidebar ? 'mb-in-2' : 'text-center mb-in-3'}`} style={{ color: '#0069af' }}>Aktuell auf Facebook</div>
        { inSidebar ? postsList : <div className="grid grid-cols-4 items-center">{postsList}</div> }
    </div>;
}

export { FacebookWidget }