import React from "react";
import { useMediaLibrary } from ".";
import fileSize from "filesize";
import { format as formatDate, formatDistanceToNow, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import classNames from "classnames";
import { useDrag } from "react-dnd";
import { motion } from "framer-motion";

const MediaListItem = props => {
    const { selectedMedia, setSelectedMedia } = useMediaLibrary();
    const { item } = props;

    const isSelected = selectedMedia && selectedMedia.id === item.id;

    // const onCheckboxChange = e => {
    //     if (isSelected) {
    //         setSelectedMedia(selectedMedia.filter(i => i.id !== item.id))
    //     } else {
    //         setSelectedMedia([...selectedMedia, item])
    //     }
    // }

    const [, drag] = useDrag(() => ({
        type: 'media',
        item
    }))

    const itemVariants = {
        hidden: { opacity: 0, x: -10 },
        show: { opacity: 1, x: 0 }
    }

    return (
        <motion.div key={item.id} variants={itemVariants} ref={drag} onClick={() => setSelectedMedia(item)} className={classNames({
            'flex hover:bg-slate-50': true,
            'bg-slate-100': isSelected,
            'border-transparent': !isSelected
        })}>
            {/* <div className="flex-0 py-2 px-4"><input type="checkbox" checked={isSelected} onChange={onCheckboxChange} /></div> */}
            <div className="flex-1 py-2 px-4 truncate">{ item.name }</div>
            <div className="flex-0 w-32 py-2 px-4">{ fileSize(item.size) }</div>
            {/* <div className="flex-0 w-44 py-2 px-4">{ formatDate(typeof item.created_at == "object" ? item.created_at : parseISO(item.created_at), 'dd.MM.yy – HH:mm', { locale: de }) }</div> */}
            <div className="flex-0 w-44 py-2 px-4" title={formatDate(typeof item.created_at == "object" ? item.created_at : parseISO(item.created_at), 'dd.MM.yy – HH:mm', { locale: de })}>{ formatDistanceToNow(typeof item.created_at == "object" ? item.created_at : parseISO(item.created_at), { locale: de, addSuffix: true, includeSeconds: true }) }</div>
        </motion.div>
    )
}

export default MediaListItem