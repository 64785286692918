import { format } from "date-fns";
import { de } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import useApi from "../../services/api";

const ServiceSliderDay = ({date}) => {
    const [proofs, setProofs] = useState('loading');
    const api = useApi();

    useEffect(() => {
        api.get('services', {
            params: {
                date: format(date, 'yyyy-MM-dd')
            }
        }).then(result => setProofs(result.data.data))
    }, [])

    const proofList = proofs !== 'loading' && proofs.map(proof => (
        <div key={proof.id} className="p-3 bg-white shadow-md border-t m-4 border-gray-200 text-sm">
            <div className="flex items-center text-lg font-medium">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2.5}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div className="flex-auto overflow-hidden text-ellipsis">{ format(new Date(proof.created_at), 'HH:mm')} Uhr</div>
            </div>
            <div className="flex items-center text-base">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
                <div className="flex-auto overflow-hidden text-ellipsis">{ proof.contractor }</div>
            </div>
            <div className="flex items-start py-3 mt-3 border-t border-b">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                </svg>
                <div className="flex-auto leading-tight">{ proof.services.join(', ') }</div>
            </div>
            <div className="text-base">
                <div className="text-gray-500"></div>
                <div className="ml-3 overflow-hidden text-ellipsis"></div>
            </div>
            <div className="leading-tight py-3">
                
            </div>
            <img src={`data:image/png;base64,${proof.signature}`} className="filter brightness-110 w-4/6 mx-auto" />
        </div>
    ))
    
    return <div className="p-2" style={{ direction: 'ltr'}} dir="ltr">
        <div className="rounded-md overflow-hidden">
            <header className="text-center text-gray-600 p-3 border-b-2 border-gray-400">
                <div className="text-gray-500">{ format(date, 'EEEE', { locale: de }) }</div>
                <div className="text-2xl font-medium">{ format(date, 'dd.MM.yyyy', { locale: de }) }</div>
            </header>
            <div>
                { proofs.length === 0 && <div className="py-8 text-center text-gray-400 italic">Keine Dienstleistungen</div> }
                { proofs === 'loading' ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-10 w-10 animate-spin mx-auto my-10 text-gray-400" fill="currentColor"><path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
                : <div>{ proofList }</div> }
            </div>
        </div>
    </div>
}

export default ServiceSliderDay