import React from "react";
import { useState, useEffect } from "react";
import useApi from "../../../../services/api";
import { useEditor } from "../../editor/Editor";
import { FacebookWidget } from "./FacebookWidget";

const FacebookSettings = () => <div>Facebook Settings</div>

export const FacebookModule = {
    name: 'Facebook News',
    description: 'Ihre Facebook Seite – Ihre Neuigkeiten. Zeigen Sie Ihren Mietern, was es neues auf dem Social Network gibt.',
    icon: 'test',
    properties: {},
    widget: FacebookWidget,
    settings: FacebookSettings
};