import React from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import useApi from "../../services/api";
import { useMediaLibrary } from ".";
import MediaGridItem from "./MediaGridItem";
import MediaListItem from "./MediaListItem";
import { motion } from "framer-motion";

const MediaPane = (props) => {
    const { media, setMedia, selectedCollection, setSelectedMedia, viewType, uploadFile } = useMediaLibrary();
    const api = useApi();

    const mediaGrid = media.map(item => <MediaGridItem item={item} key={item.id} />)
    const mediaList = media.map(item => <MediaListItem item={item} key={item.id} />)

    const [{isDropover}, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: (item, monitor) => {
            for (let file of item.files) {
                uploadFile(file);
            }
        },
        collect: monitor => ({
            isDropover: monitor.isOver() && monitor.canDrop()
        })
    })

    if (props.isLoading) {
        return <main className="relative flex-1 z-0 flex items-center justify-center" style={{ minHeight: '400px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-10 h-10 text-gray-300 animate-spin" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
        </main>
    }

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05
            }
        }
    }

    return (
        <main ref={drop} className="relative media-folder-view flex-1 z-0" onClick={() => setSelectedMedia(null)} style={{ minHeight: '300px'}}>
            
            {isDropover && <div className="absolute z-20 top-0 left-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-sm flex justify-center items-center ring-2 ring-inset ring-offset-8 ring-green-500">
                <div className="text-slate-600 text-xl px-4">Dateien zum Hochladen hier ablegen.</div>
            </div>}

            { media.length === 0 && <div className="flex items-center justify-center w-full h-full text-gray-400">
                <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 text-gray-300 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <div className="max-w-xs">
                        <div className="text-lg">Diese Sammlung ist noch leer</div>
                        <div className="text-sm">Ziehen Sie Dateien von Ihrem Computer in dieses Fenster oder klicken Sie auf "Datei hochladen".</div>
                    </div>
                </div>
            </div>}

            { viewType === 'grid' ?
                <motion.div variants={container}
                initial="hidden"
                animate="show" className="p-5 grid grid-cols-6 gap-2">{ mediaGrid }</motion.div>
            :
                <motion.div variants={container} initial="hidden" animate="show" className="w-full h-full flex flex-col" onClick={e => e.stopPropagation()}>
                    <header className="flex border-b border-slate-200">
                        {/* <div className="flex-0 py-3 px-4"><input type="checkbox" /></div> */}
                        <div className="flex-1 border-l py-3 px-4">Name</div>
                        <div className="flex-0 w-32 border-l py-3 px-4">Größe</div>
                        <div className="flex-0 w-44 border-l py-3 px-4">Hochgeladen</div>
                    </header>
                    { mediaList }
                </motion.div>
            }
        </main>
    )
}

MediaPane.defaultProps = {
    isLoading: false
}

export default MediaPane