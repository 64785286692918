import React, { useMemo } from 'react';

const ContactWidget = (props) => {

    const inSidebar = useMemo(() => ['l1', 'l2', 'r1', 'r2'].includes(props.position), [props.position]);

    const personsList = props.persons.map((person, index) => 
        <div className="flex items-center mb-in-5" key={index}>
            <div className="w-2/12 flex-none">
                <div className="bg-slate-300 rounded w-full relative overflow-hidden" style={{paddingTop: '125%'}}>
                { person.image
                ? <img className="absolute left-0 top-0 w-full h-full object-cover" src={`${ process.env.REACT_APP_API_ENDPOINT }/images/${person.image}/thumb/100x125`} />
                : <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-auto absolute bottom-0 left-0 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg> }
                </div>
            </div>
            <div className="w-10/12 flex-none leading-tight overflow-hidden pl-in-4">
                <div className="text-in-lg font-bold">{ person.name }</div>
                <div className="opacity-70 leading-tight">{ person.position }</div>

                {person.phone && <div className="mt-in-2 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="mr-in-1 h-in-4 w-in-4" fill="currentColor">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg>
                    <div>{ person.phone }</div>
                </div>}

                {person.email && <div className="flex w-full mt-in-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-in-1 h-in-4 w-in-4 flex-none" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <div className="flex-initial break-words">{ person.email }</div>
                </div>}
            </div>
        </div>
    );

    return (
        <div className="p-in-5">
            <div className={`text-in-2xl mb-in-4 ${!inSidebar && 'text-center'}`} style={{ color: '#0069af' }}>Wir sind für Sie da.</div>
            { !inSidebar ? <div className={'grid grid-cols-' + props.persons.length}>{ personsList }</div> : personsList}
            <div className={`${ inSidebar ? 'mt-in-10' : 'mt-in-4' } text-center`}>
                <button type="button" className="px-in-10 py-in-2 border border-slate-600 rounded w-full">Notfallkontakte ansehen</button>
            </div>
        </div>
    )
}

export default ContactWidget