const icons = {
    "01d": require("./01d.png"),
    "02d": require("./02d.png"),
    "03d": require("./03d.png"),
    "04d": require("./04d.png"),
    "09d": require("./09d.png"),
    "10d": require("./10d.png"),
    "11d": require("./11d.png"),
    "13d": require("./13d.png"),
    "50d": require("./50d.png")
}

export default icons;