import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

const API_BASE = process.env.REACT_APP_API_ENDPOINT + '/api/';

const useApi = () => {

    let http = axios.create();
    let { token } = useAuth();

    if (token) {
        http.interceptors.request.use(config => {
            config.headers.Authorization = 'Bearer ' + token;
            
            return config;
        }, error => Promise.reject(error))
    }

    return {
        post: (endpoint, data, config) => http.post(API_BASE + endpoint, data, config),
        get: (endpoint, config) => http.get(API_BASE + endpoint, config),
        delete: (endpoint, config) => http.delete(API_BASE + endpoint, config),
        patch: (endpoint, data, config) => http.patch(API_BASE + endpoint, data, config)
    }
}

export default useApi;