import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../services/api";
import { debounce } from "lodash";

const weekDays = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
];

const SettingsIndex = () => {
    const [openingHours, setOpeningHours] = useState([])
    const api = useApi();

    useEffect(() => {
        api.get('settings/opening_hours').then(res => {
            setOpeningHours(res.data);
        });
    }, []);

    const saveOpeningHours = debounce(() => {
        api.patch('settings/opening_hours', {
            value: openingHours.filter(item => item.from && item.to)
        }).then(res => {
            console.log(res);
        });
    }, 2000);

    return (
        <div className="px-6 lg:px-8 py-8 xl:pt-20 w-full max-w-6xl mx-auto">
            <div className="text-brand-500 text-4xl mb-10">Einstellungen</div>

            <div className="grid lg:grid-cols-2 gap-8">
                <div className="bg-white p-8 rounded-md shadow-md">
                    <h2 className="text-2xl">Öffnungszeiten</h2>
                    <p className="text-gray-500 mb-4">Hier können Sie die Öffnungszeiten für die verschiedenen Tage festlegen. Diese erscheinen dann auf allen Displays und zeigen Ihren Mietern, wann Sie den Mieterservice erreichen können.</p>
                    <table className="w-full text-center">
                        <thead>
                            <tr>
                                <th className="p-2">Tag</th>
                                <th className="p-2">Von</th>
                                <th className="p-2">Bis</th>
                                <th className="p-2"><button type="button" onClick={() => {
                                    setOpeningHours([
                                        ...openingHours,
                                        {
                                            day: 0,
                                            from: '',
                                            to: ''
                                        }
                                    ]);
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-brand-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                    </svg>    
                                </button></th>
                            </tr>
                        </thead>
                        <tbody>
                            { openingHours.map((openingHour, index) => (
                                <tr key={index}>
                                    <td className="p-2 border-t border-gray-200">
                                        <select className="block px-4 py-2 pr-8 border-gray-300 rounded" value={openingHour.day} onChange={(e) => {
                                            const newOpeningHours = [...openingHours];
                                            newOpeningHours[index].day = e.target.value;
                                            setOpeningHours(newOpeningHours);

                                            saveOpeningHours();
                                        }}>
                                            { weekDays.map((day, index) => (
                                                <option key={index} value={index}>{day}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="p-2 border-t border-gray-200"><input type="time" className="rounded py-2 border-gray-300" value={openingHour.from} onChange={(e) => {
                                        const newOpeningHours = [...openingHours];
                                        newOpeningHours[index].from = e.target.value;
                                        setOpeningHours(newOpeningHours);
                                        saveOpeningHours();
                                    }} /></td>
                                    <td className="p-2 border-t border-gray-200"><input type="time" className="rounded py-2 border-gray-300" value={openingHour.to} onChange={(e) => {
                                        const newOpeningHours = [...openingHours];
                                        newOpeningHours[index].to = e.target.value;
                                        setOpeningHours(newOpeningHours);
                                        saveOpeningHours();
                                    }} /></td>
                                    <td className="p-2 border-t border-gray-200"><button type="button" onClick={() => {
                                        openingHours.splice(index, 1)
                                        setOpeningHours([ ...openingHours ]);

                                        console.log(openingHours);
                                        saveOpeningHours();
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-brand-500" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                        </svg>    
                                    </button></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default SettingsIndex;