import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Navigate } from 'react-router-dom'

const RequireAuth = ({ children, permission }) => {
    const { user, userHasPermission } = useAuth();

    if (!user) {
        return <Navigate to="/login" />
    }

    if (permission && !userHasPermission(permission)) {
        return <Navigate to="/ads" />
    }

    return children;
}

export default RequireAuth;