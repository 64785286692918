import React from "react";

export const TextWidget = props => {
    return <div className="w-full h-full flex items-center text-center leading-tight justify-center p-in-3 transition-colors duration-300 break-words" style={{
        backgroundColor: props.backgroundColor,
        fontSize: props.fontSize + 'em',
        color: props.fontColor
    }}>
        <div>{ props.text.split('\n').map((item, key) => <span key={key}>{item}<br/></span>) }</div>
    </div>
}
