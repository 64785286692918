import React from "react";
import { useDrag } from 'react-dnd'
import { useEditor } from "./Editor";

const ToolboxItem = (props) => {

    const [{opacity}, dragRef] = useDrag(
        (e) => ({
            type: props.id,
            item: {
                properties: props.properties,
                id: props.id
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.1 : 1
            })
        }),
        []
    )

    return (
        <div ref={dragRef} style={{opacity}} className="p-4 bg-white cursor-move rounded mb-2 border border-slate-200 transition-all hover:shadow-xl flex items-center">
            {/* <div className="h-14 w-14 bg-slate-500 rounded-full"></div> */}
            <div className="flex-1 ml-4">
                <div className="text-bold">{ props.title }</div>
                {/* <div className="text-sm">{ props.description }</div> */}
            </div>
        </div>
    )
}

const Toolbox = () => {
    const { modules } = useEditor();

    return (
        <div>
            { Object.keys(modules).map(key => (
                <ToolboxItem
                    key={key}
                    id={key}
                    properties={modules[key].properties}
                    title={modules[key].name}
                    description={modules[key].description} />
            )) }
        </div>
    )
}

export { Toolbox }