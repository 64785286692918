import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useApi from "../../../../services/api";
import { useEditor } from "../../editor/Editor";
import { format as formatDate, parse } from "date-fns";
import { de } from "date-fns/locale"
import debounce from 'lodash';
import icons from './weather-icons';

export const WeatherWidget = props => {
    const [weather, setWeather] = useState('loading');
    const { display } = useEditor();
    const api = useApi();

    useEffect(() => {
        api.get(`data/${ display.id }/weather`, {
            params: {
                lat: display.lat,
                lng: display.lng
            }
        }).then(result => setWeather(result.data.days))
    }, [props.position])

    const inSidebar = useMemo(() => ['l1', 'l2', 'r1', 'r2'].includes(props.position), [props.position]);

    if (weather === "loading") {
        return <div className="w-full h-full flex justify-center items-center text-in-xl">Wetter wird geladen...</div>
    }

    const weatherView = inSidebar ? <div className="px-in-3">
            { weather.map((day, index) => (
                <div key={index} className="flex items-center">
                    <img className="h-in-24 w-auto mr-in-6" src={icons[day.icon]} />
                    <div>
                        <div className="text-in-xl text-slate-600 font-bold">{ formatDate(new Date(day.dt * 1000), 'EEEE', { locale: de }) }</div>
                        <div className="leading-tight text-slate-500">{ day.description }</div>
                        <div className="mt-in-1 text-in-xl">{ Math.round(day.temp_min) }° – { Math.round(day.temp_max) }° C</div>
                    </div>
                </div>
            )) }
        </div> : <div className="px-in-10 grid h-full items-center grid-cols-4">
        { weather.map((day, index) => (
            <div key={index} className="text-center p-3">
                <img className="h-in-28 w-auto mx-auto" src={icons[day.icon]} />
                <div className="text-in-xl text-slate-600 font-bold">{ formatDate(new Date(day.dt * 1000), 'EEEE', { locale: de }) }</div>
                <div className="leading-tight text-slate-500">{ day.description }</div>
                <div className="mt-in-1 text-in-xl">{ Math.round(day.temp_min) }° – { Math.round(day.temp_max) }° C</div>
            </div>
        )) }
    </div>;

    return (<>
        { weatherView }
    </>)
}
