import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Header = ({sidebarOpen, setSidebarOpen}) => {

    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout().then(result => navigate('/login', { replace: true }))
    }


    return (
        <header className="sticky top-0 flex-none bg-white border-b border-gray-200 z-30 px-4 xl:px-10">
            <div className="flex items-center justify-between h-20 -mb-px">

                {/* Header: Left side */}
                <div className="flex">

                    {/* Hamburger button */}
                    <button
                    className="text-slate-500 hover:text-slate-600 lg:hidden"
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4" y="5" width="16" height="2" />
                            <rect x="4" y="11" width="16" height="2" />
                            <rect x="4" y="17" width="16" height="2" />
                        </svg>
                    </button>

                </div>

                {/* Header: Right side */}
                <div className="ml-auto flex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                    </svg>
                    <button onClick={handleLogout} className=" text-left leading-none">
                        { user.name }
                        <span className="text-sm block leading-none text-gray-400 -mb-1">abmelden</span>
                    </button>
                </div>
            </div>
        </header>
    )
}

export default Header;