import React, { useState } from "react";
import {MediaLibrary} from '../media-library';
import MediaPicker from "../media-library/MediaPicker";


const MediaIndex = props => {

    const [mediaPickerOpen, setMediaPickerOpen] = useState(false)

    return (
        <div>

            <MediaPicker isOpen={mediaPickerOpen} accept="image/*" onConfirm={media => {
                console.log(media);
                setMediaPickerOpen(false);
            }} onAbort={() => setMediaPickerOpen(false)} />

            <div className="max-w-6xl w-full mt-32 mx-auto text-center">
                <button type="button" className="py-2 px-3 bg-gray-500 rounded text-white" onClick={() => setMediaPickerOpen(true)}>Choose Media</button>
            </div>

            <div className="max-w-6xl w-full mt-32 mx-auto">
                <MediaLibrary />
            </div>
            
        </div>
    );
}

export default MediaIndex;