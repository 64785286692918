import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useApi from "../../services/api";
import CreateDisplay from "./CreateDisplay";
import { MediaLibrary } from '../media-library'
import { motion } from "framer-motion";

const Index = () => {
    const [displays, setDisplays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const api = useApi();
    const { user } = useAuth();

    useEffect(() => {
       api.get('displays').then(result => {
           setDisplays(result.data);
           setLoading(false);
       })
    }, [])

    const containerMotion = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 2
            }
        }
    }

    const displayMotion = {
        hidden: { opacity: 0, y: 50 },
        show: { opacity: 1, y: 0 }
    }
      

    const displayList = displays.map((display, index) => (
        <NavLink key={display.id} to={`/edit/${ display.id }`} className="text-center">
            <motion.div className={
                `display-list-preview relative bg-gray-700 border-t-2 border-t-gray-400 p-2 pb-4 rounded-lg shadow-lg hover:shadow-xl
                ${ display.online ? 'display-online' : 'display-offline'}
            `} whileHover={{ scale: 1.025 }}
            variants={displayMotion}
            > 
                <div className="display-list-preview-inner bg-white p-9 rounded-sm relative">
                    { display.online && <img src={display.screenshot} className="absolute left-0 top-0 w-full h-full" />}
                </div>
                <div className={`
                    absolute bottom-1 mx-auto left-0 right-0 w-3/12 h-1 rounded-full
                    ${ display.online ? 'bg-green-600' : 'bg-red-600' }
                `} />
            </motion.div>
            <div className="mt-5 text-lg leading-tight font-medium">{ display.name }</div>

            { display.online ? (
                <div className="text-green-600"><span className="inline-block w-3 h-3 mr-2 bg-green-600 rounded-full"></span> in Betrieb</div>
            ) : (
                <div className="text-red-600"><span className="inline-block w-3 h-3 mr-2 bg-red-600 rounded-full"></span> ist nicht in Betrieb</div>
            ) }
           
        </NavLink>
    ));

    return (
        <div className="px-6 lg:px-8 py-8 w-full xl:pt-20 max-w-9xl mx-auto display-index">

            <CreateDisplay isOpen={createModalOpen} close={() => setCreateModalOpen(false)} />

            <div className="max-w-7xl mx-auto display-list">
                <h1 className="text-3xl lg:text-4xl text-brand-500">Willkommen zurück!</h1>
                <div className="lg:flex items-center">
                    <div className="lg:pr-20">
                        <p className="mt-2 text-lg text-gray-500">Hier sehen Sie all Ihre derzeit vorhandenen Displays, sowie deren aktueller Status. Klicken Sie auf eines ihrer Displays, um dessen Inhalte zu designen. Um ein neues Display anzulegen, klicken Sie bitte auf den Button &quot;Neues Display&quot;.</p>
                    </div>
                    <button type="button" className="border mt-5 lg:mt-0 border-gray-500 text-gray-500 rounded whitespace-nowrap text-2xl px-14 py-4 hover:bg-gray-500 hover:text-white hover:shadow-2xl" onClick={() => setCreateModalOpen(true)}>Neues Display</button>
                </div>
                { loading && <div>lade...</div>}
                { displays ? (
                    <motion.div
                        className="grid sm:grid-cols-2 lg:grid-cols-3 my-20 gap-16"
                        variants={containerMotion}
                        initial="hidden"
                        animate="show">{ displayList }</motion.div>
                ) : 'keine Displays vorhanden' }
                

                {/* <MediaLibrary /> */}

            </div>
        </div>
    )
}

export default Index;