import React from "react";
import { addDays, subDays } from 'date-fns'
import Slider from 'react-slick-pnth'
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ServiceSliderDay from "./ServiceSliderDay";
import "./services-slider.scss";

const ServicesSlider = (props) => {

    const slider = useRef()
    const [days, setDays] = useState([])

    useEffect(() => setDays([...Array(6).keys()].map(i => subDays(new Date(), i))), []);

    const sliderSettings = {
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        rtl: true,
        addaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    const extendSlides = index => {
        const totalSlides = days.length;
        const lastVisibleSlide = index + 4;
        const lastDate = new Date(days[days.length - 1]);

        if (lastVisibleSlide > totalSlides - 3) {
            console.log('extend slides');
            [...Array(4).keys()].forEach(i => {
                days.push(subDays(lastDate, i+1));
                setDays([...days]);
            });
        }
    }

    return (
        <Slider ref={slider} {...sliderSettings} afterChange={extendSlides} className={[props.className, 'services-slider']}>
            { days.map((day, index) => <ServiceSliderDay key={index} date={day} />) }
        </Slider>     
    )
}

export default ServicesSlider