import React from "react";
import { useState } from "react";
import MediaPicker from "../../../media-library/MediaPicker";
import { ReactSortable } from "react-sortablejs";

const ImageSliderSettings = props => {

    // const [images, setImages] = useState(props.images)
    const [mediaPickerOpen, setMediaPickerOpen] = useState(false)

    const addImage = media => {
        props.images.push({
            id: media.id,
            url: ''
        });

        setMediaPickerOpen(false);
        props.setProps({ images: props.images })
    }

    const deleteImage = index => {
        props.images.splice(index, 1);

        // setImages(images);
        props.setProps({images: props.images});
    }

    const imagesList = props.images.map((image, index) => (
        <div key={index} className="p-1 bg-white shadow-lg relative">
            <img src={`${ process.env.REACT_APP_API_ENDPOINT }/images/${ image.id }/thumb/400x250`} width="400" height="250" />
            <input type="text" className="w-full p-1 text-sm border-0 rounded-sm" placeholder="https://..." value={image.url}
                onChange={e => {
                    props.images[index].url = e.target.value;
                    props.setProps({ images: props.images });
                }} />
            <button onClick={() => deleteImage(index)} className="absolute -right-2 -top-2 bg-red-500 text-white w-6 h-6 flex justify-center items-center rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    ))

    return (
        <div>
            <MediaPicker
                accept="image/*"
                isOpen={mediaPickerOpen}
                onAbort={() => setMediaPickerOpen(false)}
                onConfirm={addImage}
            />

            <div className="font-medium">Bilder-Karussell bearbeiten</div>
            <div className="text-sm leading-snug text-gray-600">Hier haben Sie die Möglichkeit, die Bilder des Karussells zu bearbeiten. Optional können Sie jedem Bild eine Internetadresse angeben, welche auf dem Display geöffnet wird, wenn der Betrachter das Bild antippt.</div>

            <ReactSortable list={props.images} setList={images => props.setProps({ images })} className="grid grid-cols-2 gap-3 my-5">
                { imagesList }
            </ReactSortable>

            <button type="button" onClick={() => setMediaPickerOpen(true)} className="px-6 w-full rounded py-1 border border-gray-500 hover:bg-gray-500 hover:shadow-lg hover:text-white">Bild hinzufügen</button>
        </div>
    )

};

ImageSliderSettings.defaultProps = {
    images: []
};

export { ImageSliderSettings };
