import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./assets/scss/base.scss";

import AppLayout from "./components/AppLayout";
import Login from './components/Login'
import DisplaysIndex from './components/displays/Index'
import EditDisplay from "./components/displays/Edit";
import RequireAuth from "./components/RequireAuth";
import MediaIndex from "./components/media";
import ServicesIndex from './components/services'
import AdsIndex from "./components/ads";
import SettingsIndex from './components/settings';
import GamesIndex from './components/games';

const App = () => {
    return (
        <>
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/login" element={<Login />} />
                <Route element={<AppLayout />}>
                    <Route path="/" element={<RequireAuth permission="displays"><DisplaysIndex /></RequireAuth>} />
                    <Route path="edit/:displayId" element={<RequireAuth permission="displays"><EditDisplay /></RequireAuth>} />
                    <Route path="media" element={<RequireAuth permission="media"><MediaIndex /></RequireAuth>} />
                    <Route path="services" element={<RequireAuth permission="services"><ServicesIndex /></RequireAuth>} />
                    <Route path="ads" element={<RequireAuth permission="ads"><AdsIndex /></RequireAuth>} />
                    <Route path="games" element={<RequireAuth permission="games"><GamesIndex /></RequireAuth>} />
                    <Route path="settings" element={<RequireAuth permission="settings"><SettingsIndex /></RequireAuth>} />
                </Route>
            </Routes>
        </>
    )
}

export default App;