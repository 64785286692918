import NewsWidget from './NewsWidget'
import NewsSettings from './NewsSettings'
 
const module = {
    name: 'Aktuelle Neuigkeiten',
    description: 'Zeigen Sie ihren Mietern an, was es neues in ihrer Stadt gibt.',
    icon: 'test',
    properties: {
        query: ''
    },
    widget: NewsWidget,
    settings: NewsSettings
}

export default module;