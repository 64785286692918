import React from "react";
import { useEditor } from "./Editor";
import { motion } from "framer-motion";
import useApi from "../../../services/api";
import { useState } from "react";
import classNames from "classnames";

const Settings = () => {
    const { selected, setSelected, modules, widgets, setWidgets, display } = useEditor();
    const api = useApi();
    const [isSaving, setIsSaving] = useState(false)

    var selectedWidget = null,
        selectedModule = null;

    if (selected) {
        selectedWidget = widgets[selected];
        selectedModule = modules[selectedWidget.widget];
    }

    const setProps = (props) => {
        widgets[selected].props = props;

        setWidgets({
            ...widgets,
        });
    }

    const renderSettingsWidget = () => {
        return React.createElement(
            selectedModule.settings,
            {
                ...selectedWidget.props,
                setProps
            }
        )
    }

    const saveWidget = () => {
        setIsSaving(true);

        api.patch(`displays/${display.id}/widgets/${selected}`, {
            position: selected,
            widget: selectedWidget.widget,
            props: selectedWidget.props
        }).then(() => {
            setIsSaving(false);
            setSelected(null);
        });  
    }

    const settingsVariants = {
        closed: { translateX: '100% '},
        open: { translateX: '0%' }
    };

    return (
        <motion.div className="absolute left-0 top-0 w-full h-full bg-gray-50 shadow-xl" variants={settingsVariants} animate={ selected ? 'open' : 'closed'}>
            {selected && (
                <>
                    <div className="p-8 border-b bg-white border-slate-200">
                        {/* <button onClick={e => setSelected(false)}>schließen</button> */}
                        <div className="text-xl mb-1 font-medium">{ selectedModule.name }</div>
                        <div className="text-sm text-slate-500">{ selectedModule.description }</div>
                    </div>
                    <div className="p-8">
                        { selectedModule.settings ? renderSettingsWidget() : null}
                    </div>
                    <div className="border-t border-gray-200 p-6">
                        <button
                            type="button"
                            onClick={saveWidget}
                            disabled={isSaving}
                            className={classNames(
                                'w-full py-2  text-white',
                                {
                                    'bg-gray-300': isSaving,
                                    'bg-gray-500': !isSaving
                                }
                            )}
                        >{isSaving ? 'Speichere Widget...' : 'Widget speichern'}</button>
                    </div>
                </>
            )}
        </motion.div>
    )
}

export { Settings }