import React from "react";
import classNames from "classnames";
import { useMediaLibrary } from ".";
import useApi from "../../services/api";
import { useDrop } from 'react-dnd'
import { useCallback } from "react";
import { useEffect } from "react";

const CollectionItem = (props) => {

    const { media, setMedia, collections, setCollections, setSelectedCollection, selectedCollection } = useMediaLibrary();
    const { collection } = props;
    const api = useApi();

    const updateCollectionName = () => {
        return true;
    }

    const deleteCollection = collection => {
        if (window.confirm('Soll die Sammlung wirklich gelöscht werden? Alle enthaltenen Medien werden ebenfalls gelöscht!')) {
            setCollections(collections.filter(c => c.id !== collection.id));
            api.delete(`media_collections/${ collection.id }`);
            setSelectedCollection(null);
        }
    }

    const [{ isDropover }, drop] = useDrop(() => ({
        accept: 'media',
        drop: (item) => {
            if (item.collection_id !== collection.id) {
                setMedia(media.filter(i => i.id !== item.id))
                
                api.patch(`media/${ item.id }`, {
                    collection_id: collection.id
                });
            }
        },
        collect: monitor => ({
            isDropover: monitor.canDrop() && monitor.isOver()
        })
    }), [media])

    const isSelected = (selectedCollection && selectedCollection.id === collection.id) || (collection.id === null && selectedCollection === null);

    const classes = classNames({
        'flex items-center group py-4 pl-8 pr-5  hover:bg-gray-100 truncate cursor-pointer transition': true,
        'bg-gray-200': isSelected,
        'hover:pr-0': !collection.edit,
        // 'border-b': collection.id === null,
        'ring-2 ring-inset ring-green-500 cursor-add': isDropover
    })

    const editCollection = (e, collection) => {
        e.stopPropagation();
                            
        setCollections(collections.map(c => {
            if (c.id === collection.id) {
                c.edit = true;
            }

            return c;
        }))
    }

    return (
        <div ref={drop} className={classes} onClick={() => setSelectedCollection(collection)}>
            { !isSelected ?
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 flex-shrink-0 text-slate-500" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                </svg>
            :
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 flex-shrink-0 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z" clipRule="evenodd" />
                    <path d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z" />
                </svg>
            }
            { collection.edit ?
                <form onSubmit={updateCollectionName} className="flex-1">
                    <input type="text" className="w-full rounded border-slate-300"
                        autoFocus={true} value={collection.name}
                        onChange={e => collection.name = e.target.value} />
                </form>
            :
                <>
                    <div className="flex-1 truncate">{ collection.name }</div>
                    <div className={classNames({
                        'ml-auto pl-2 mr-2 hidden group-hover:block': true
                    })}>
                        <button onClick={(e) => editCollection(e, collection)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                        <button onClick={() => deleteCollection(collection)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </button>   
                    </div>
                </>
            }
        </div>
    )
}

export default CollectionItem