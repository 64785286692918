import React, { useEffect } from 'react';
import { useState } from 'react';
import useApi from '../../services/api';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMemo } from 'react';
import { de } from 'date-fns/locale';
import { format, getMonth } from 'date-fns';

const Statistics = () => {
    const api = useApi();
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        api.get('ads/statistics', {

        }).then(result => setStatistics(result.data));
    }, [])

    if (!statistics) {
        return null;
    }

    const minutesPerDayData = Object.keys(statistics.days).map(day => {
        return {
            name: day,
            date: (new Date()).setDate(day),
            minutes: statistics.days[day] / 60
        }
    });

    const minutesToday = statistics.days[(new Date()).getDate()] / 60;

    return <div className='mb-20 grid grid-rows-2 grid-cols-2 gap-5'>
        <div className='col-span-1 bg-white p-8 flex items-center rounded-md shadow-md'>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='w-7 h-7 mb-6 text-slate-500 fill-current' width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/></svg>
                <div className="text-3xl text-brand-500">{ format(new Date(), 'MMMM', { locale: de }) }</div>
                <div className='text-lg text-slate-500 leading-none'>Anzeigeminuten</div>
            </div>
            <div className="ml-auto text-8xl text-brand-500 leading-none -mb-3 font-medium">{ Math.floor(statistics.totalSecondsMonth / 60) }</div>
        </div>
        
        <div className="row-span-2 relative col-span-1 bg-white p-8 rounded-md shadow-md">
            <div className='flex'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='w-7 h-7 mb-6 text-slate-500 fill-current' width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/></svg>
                <div className="ml-auto text-right">
                    <div className="text-3xl text-brand-500">Verlauf</div>
                    <div className='text-lg text-slate-500 leading-none'>{ format(new Date(), 'MMMM', { locale: de }) }</div>
                </div>
            </div>
            <div className='absolute bottom-2 left-10 right-0'>
                <BarChart
                
                  width={520}
                  height={260}
                  data={minutesPerDayData}
                  ddmargin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                    <XAxis dataKey="name" axisLine={{ stroke: '#aaa' }} tick={{ fill: '#aaa' }} />
                
                    <Tooltip content={"sdfsdf"} />
                    <Bar dataKey="minutes" label="df" fill="#E4898C" />
                </BarChart>
            </div>
        </div>

        <div className='col-span-1 bg-white p-8 flex items-center rounded-md shadow-md'>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='w-7 h-7 mb-6 text-slate-500 fill-current' width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/></svg>
                <div className="text-3xl text-brand-500">Heute</div>
                <div className='text-lg text-slate-500 leading-none'>Anzeigeminuten</div>
            </div>
            <div className="ml-auto text-8xl text-brand-500 leading-none -mb-3 font-medium">{ Math.floor(minutesToday) }</div>
        </div>
    </div>    
}

export default Statistics;