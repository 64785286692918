import {ImageSliderWidget} from "./ImageSliderWidget";
import { ImageSliderSettings } from "./ImageSliderSettings";

export default module = {
    name: 'Bilder-Karussell',
    description: 'Bilder sagen mehr als tausend Worte – zeigen Sie Ihre Veranstaltungen und Aktionen in einem Bilder-Slider.',
    icon: 'test',
    properties: {
        images: []
    },
    widget: ImageSliderWidget,
    settings: ImageSliderSettings
};
